import {
  Button,
  MenuItem,
  Stack,
  Typography,
  styled,
  Grid,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../dashboard/dashboard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataTable from "./PaymentTable";
import PaymentsIcon from "@mui/icons-material/Payments";
import CustomDialogue from "../reuseableComp/Modal";
import { CustomField } from "../client";
import { Back } from "../../assets/Svg";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Logo from "../../assets/erorex-Logo.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";

import {
  getBanks,
  getClient,
  getPayment,
  savePaymentNew,
  updateClientBalance,
  updateClientPaymentSale,
  updateLedgerSalePayments,
  updateSpecificPayment,
} from "../../apiservices";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import Search from "../searchBar";
import moment from "moment";
export const Btn = styled(Button)({
  height: "36px",
  padding: "4px",
  borderRadius: "4px",
  textTransform: "none",
  background: "#FFFFFF",
  color: "#52b335",
  marginRight: 10,
  "&:hover": {
    background: "#F6F6F6",
  },
  "&:active": {
    background: "#FFFFFF",
  },
});
export const Typo = styled(Typography)({
  fontWeight: 700,
  fontSize: "20px",
  color: "#52b335",
});
export const Lay = styled(Stack)({
  // borderRadius: "8px",
  padding: 15,
  border: "1px solid #9F9E9E",
});
const Payment = () => {
  const theme = useTheme();
  const componentRef = useRef();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [state, setState] = useState({
    openModal: false,
    viewPayment: false,
    clientData: [],
    banksList: [],
    selectedMode: "",
    selectedClient: "",
    selectedBank: "",
    amount: 0,
    viewLedger: false,
    payments: {},
    paymentData: [],
    accNum: "",
    searchData: [],
    editedData: {},
    currentBalance: 0,
    accountsArr: [],
    uniBanksArr: [],
    selectedAcc: {},
    prevBalance: 0,
    check: false,
    updatedData: {},
  });
  const getClientsData = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    let obj = {
      company_code: userData.company_code,
    };
    let res = await getClient(obj);
    res.client.forEach((item, id) => {
      item["id"] = id + 1;
      item["name"] = item.clientName;
    });
    if (res) {
      setState((prev) => ({ ...prev, clientData: res.client }));
    }
  };
  const getBanksList = async () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    let obj = {
      company_code: userData.company_code,
    };
    let res = await getBanks(obj);
    let arr = [];
    res.bankDetails.map((item) => arr.push(item.bank_name));
    const arrayWithDuplicates = arr;
    const uniqueArray = [...new Set(arrayWithDuplicates)];
    if (res) {
      setState((prev) => ({
        ...prev,
        banksList: res.bankDetails,
        uniBanksArr: uniqueArray,
      }));
    }
  };
  useEffect(() => {
    getClientsData();
    getBanksList();
    getPayments();
  }, []);
  const getPayments = async () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    let obj = {
      company_code: userData.company_code,
    };
    let res = await getPayment(obj);
    if (res) {
      setState((prev) => ({
        ...prev,
        paymentData: res.payments,
        searchData: res.payments,
      }));
    }
  };
  const handleModal = (data = "") => {
    setState((prev) => ({
      ...prev,
      openModal: !prev.openModal,
      selectedClient: data?.payer,
      selectedMode: data?.type,
      selectedBank: data?.bank_name && `${data?.bank_name}`,
      selectedAcc: data?.account_title && `${data?.account_title}`,
      updatedData: data,
      amount: data?.amount,
    }));
    let arr = [];
    let filtered = state.banksList.filter(
      (item) => item.bank_name === data?.bank_name
    );
    filtered.map((item) => arr.push(item.address));
    setState((prev) => ({ ...prev, accountsArr: filtered }));
  };
  const view = async (data = "") => {
    setState((prev) => ({
      ...prev,
      viewPayment: !prev.viewPayment,
      payments: data,
    }));
    if (data) {
      let filtered = state.clientData.filter(
        (item) => item.clientName === data.payer
      );
      setState((prev) => ({
        ...prev,
        currentBalance: filtered && filtered[0]?.currentBalance,
      }));
    }
  };
  const handleChange = (e) => {
    let { name } = e.target;
    let { value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
    if (name === "selectedBank") {
      let arr = [];
      let filtered = state.banksList.filter((item) => item.bank_name === value);
      filtered.map((item) => arr.push(item.address));
      setState((prev) => ({ ...prev, accountsArr: filtered }));
    }
    if (name === "selectedAcc") {
      state.banksList.map(
        (item) =>
          item.address === value &&
          setState((prev) => ({ ...prev, accNum: item.account_number }))
      );
    }
    if (name === "selectedClient") {
      let filtered = state.clientData.filter(
        (item) => item.clientName === value
      );
      setState((prev) => ({
        ...prev,
        prevBalance: filtered[0].currentBalance,
      }));
    }
  };
  const savePayment = async () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    if (state.check) {
      let filtered = [];
      if (state.selectedClient?.clientName) {
        filtered = state.clientData.filter(
          (item) => item.clientName === state.selectedClient.clientName
        );
      } else {
        filtered = state.clientData.filter(
          (item) => item.clientName === state.selectedClient
        );
      }
      let obj = {
        date: state.updatedData.date,
        paymentId: state.updatedData.paymentId,
        user_id: userData.email,
        bank_name: state.selectedBank,
        received_by: userData.name,
        payer: state.selectedClient,
        type: state.selectedMode,
        amount: state.amount,
        account_number: state.accNum,
        total: state.prevBalance,
        account_title: state.selectedAcc,
      };
      let updateLedgerSale = {
        _id: filtered[0]?._id,
        clientEntry: {
          srNum: state.updatedData.paymentId,
          amount: state.amount,
          paymentNumber: state.updatedData.paymentNumber,
          date: moment().valueOf(),
          type: "payment",
        },
      };
      let res = await updateSpecificPayment(obj);
      await updateClientBalance(updateLedgerSale);
      if (res) {
        toast.success("payment updated successfully");
        handleModal();
        getPayments();
      }
    } else {
      let filtered = state.clientData.filter(
        (item) => item.clientName === state.selectedClient.clientName
      );
      let obj = {
        company_code: userData.company_code,
        user_id: userData.email,
        bank_name: state.selectedBank,
        received_by: userData.name,
        payer: state.selectedClient.clientName,
        payerId: filtered[0]?._id,
        type: state.selectedMode,
        amount: state.amount,
        account_number: state.accNum,
        total: state.prevBalance,
        account_title: state.selectedAcc,
      };
      let updateObj = {
        _id: filtered[0]?._id,
        type: "payment",
        paid: state.amount,
      };
      let res = await savePaymentNew(obj);
      await updateClientPaymentSale(updateObj);
      if (res._id) {
        let updateLedgerSale = {
          _id: filtered[0]?._id,
          clientArray: {
            amount: state.amount,
            paymentNumber: res.paymentNumber,
            srNum: res.paymentId,
            type: "payment",
            date: moment().valueOf(),
          },
        };
        await getPayments();
        handleModal();
        await getClientsData();
        await updateLedgerSalePayments(updateLedgerSale);
        toast.success("payment saved successfully");
      }
    }
  };
  const updateData = (data) => {
    setState((prev) => ({ ...prev, paymentData: data }));
  };
  const ComponentToPrint = React.forwardRef((props, ref) => (
    <Stack
      width={"98%"}
      sx={{
        padding: 1,
      }}
      ref={ref}
      id="content-to-print">
      <Stack
        sx={{ mb: 2, mt: 1 }}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"end"}
        spacing={3}>
        <img src={Logo} alt="logo" width={"250"} height={"70"} />

        <Stack>
          <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
            ZR Communication
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
            Shop No 23, 1st Floor, Naaz Market, Liaqat Rd,
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
            Near Moti Masjid, Rawalpindi
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
            Cell No : 03205338888 Email info@erorex.com
          </Typography>
        </Stack>
      </Stack>
      <Stack justifyContent={"center"} alignItems={"center"}>
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Payments
        </Typography>
      </Stack>
      <Grid
        container
        style={{ marginBottom: "10px", marginTop: 8 }}
        spacing={1}>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#52B335",
              color: "#fff",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}>
              {" "}
              Received By:
            </Typography>
          </Stack>
          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {state.payments.received_by}
            </Typography>
          </Stack>
        </Grid>

        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#52B335",
              color: "#fff",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}> Payer:</Typography>
          </Stack>
          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {state.payments.payer}
            </Typography>
          </Stack>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#52B335",
              color: "#fff",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}>
              Payment #
            </Typography>
          </Stack>

          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {" "}
              {state.payments.paymentNumber}
            </Typography>
          </Stack>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#52B335",
              color: "#fff",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}>Date</Typography>
          </Stack>
          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {" "}
              {state.payments.date}
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Lay
        direction={"row"}
        spacing={2}
        justifyContent={"space-between"}
        sx={{ paddingLeft: 2, paddingRight: 2, marginTop: 4 }}
        alignItems={"center"}>
        <Typography>Transaction Mode:</Typography>
        <Typography>
          {state.payments.type === "Bank"
            ? state.payments.bank_name
            : state.payments.type}
        </Typography>
      </Lay>
      {state.payments.type === "Bank" && (
        <Lay
          direction={"row"}
          spacing={2}
          justifyContent={"space-between"}
          sx={{ paddingLeft: 2, paddingRight: 2 }}
          alignItems={"center"}>
          <Typography>Account Title & Number :</Typography>
          <Typography>{`${state.payments?.account_title} [${state.payments?.account_number}]`}</Typography>
        </Lay>
      )}
      <Lay
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ paddingLeft: 2, paddingRight: 2 }}
        alignItems={"center"}>
        <Typography>Amount:</Typography>
        <Typography>{state.payments.amount}</Typography>
      </Lay>
      <Lay
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ paddingLeft: 2, paddingRight: 2 }}
        alignItems={"center"}>
        <Typography>Current Balance:</Typography>
        <Typography>{state.currentBalance}</Typography>
      </Lay>
    </Stack>
  ));
  const handleCheck = () => {
    setState((prev) => ({ ...prev, check: !prev.check }));
  };
  const handleOnSearch = (string) => {
    setState((prev) => ({ ...prev, selectedClient: string }));
  };
  const handleOnSelect = (item) => {
    setState((prev) => ({ ...prev, selectedClient: item.clientName }));
  };
  const handleClientChange = (e, value) => {
    if (value) {
      setState((prev) => ({
        ...prev,
        selectedClient: value,
      }));
    }
  };
  return (
    <Stack sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <>
        {!state.viewPayment ? (
          <>
            <Layout
              justifyContent="space-between"
              alignItems={"center"}
              direction={"row"}>
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "24px",
                  fontWeight: 700,
                }}></Typography>
              <Typography
                sx={{ color: "#FFF", fontSize: "24px", fontWeight: 700 }}>
                Payments
              </Typography>
              <Btn onClick={handleModal}>
                <AddCircleIcon fontSize="small" sx={{ mx: 1 }} /> Add new
                payment
              </Btn>
            </Layout>
            <Stack sx={{ padding: "20px" }}>
              <Stack
                justifyContent={"start"}
                alignItems={"start"}
                sx={{
                  width: "50%",
                }}>
                <Search
                  data={state.searchData}
                  updateData={(data) => updateData(data)}
                  place1={" Search by payment no"}
                  place2={"& Client name"}
                />
              </Stack>
              <DataTable
                editPayment={handleModal}
                view={view}
                data={state.paymentData}
                getData={getPayments}
                handleCheck={handleCheck}
                clientData={state.clientData}
              />
            </Stack>
          </>
        ) : (
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Layout
              justifyContent="space-between"
              alignItems={"center"}
              direction={"row"}>
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "24px",
                  fontWeight: 700,
                  padding: 1,
                }}>
                {!smallScreen && "Payment Details"}
              </Typography>
              <Stack direction={"row"}>
                {/* <Btn onClick={view}>
                  <AppReg fontSize="small" sx={{ mx: 1 }} /> View All Payments
                </Btn> */}
                <ReactToPrint
                  trigger={() => (
                    <Btn>
                      <PictureAsPdfIcon fontSize="small" sx={{ mx: 1 }} />
                      {!smallScreen && "PDF"}
                    </Btn>
                  )}
                  content={() => componentRef.current}
                />

                <ReactToPrint
                  trigger={() => (
                    <Btn>
                      <PrintIcon fontSize="small" sx={{ mx: 1 }} />{" "}
                      {!smallScreen && "Print"}
                    </Btn>
                  )}
                  content={() => componentRef.current}
                />

                <Btn onClick={view}>
                  <Back sx={{ mx: 1 }} />
                  {!smallScreen && "Back"}
                </Btn>
              </Stack>
            </Layout>
            <ComponentToPrint ref={componentRef} />
          </Stack>
        )}
      </>
      <CustomDialogue
        open={state.openModal}
        handleClose={handleModal}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <PaymentsIcon sx={{ color: "#52b335" }} fontSize="large" />
            <Typo>Add new payment</Typo>
          </Stack>
        }
        content={
          <Stack sx={{ padding: 2 }}>
            <Stack sx={{ mb: 2 }}>
              <Autocomplete
                options={state.clientData}
                fullWidth
                value={state.selectedClient}
                onChange={(e, value) => handleClientChange(e, value)}
                getOptionLabel={(option) => option?.clientName || option}
                sx={{ mb: 2 }}
                renderInput={(params) => (
                  <CustomField
                    {...params}
                    label="Select or Type client name"
                    variant="outlined"
                    // InputLabelProps={{ shrink: true }}
                  />
                )}
              />
              {/* <ReactSearchAutocomplete
                styling={{
                  mb: 2,
                  background: "#F6F6F6",
                  borderRadius: "10px",
                  boxShadow: "rgb(77, 175, 81) 0px 3px 7px",
                  zIndex: 9999,
                }}
                items={state.clientData}
                onSearch={handleOnSearch}
                onSelect={handleOnSelect}
                autoFocus
                inputSearchString={state.selectedClient}
                showItemsOnFocus={true}
              /> */}
            </Stack>
            <CustomField
              fullWidth
              id="outlined-select-currency"
              select
              label="Select Transaction Mode"
              name="selectedMode"
              value={state.selectedMode}
              onChange={handleChange}
              sx={{ mb: 2 }}>
              {["Cash", "Bank"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </CustomField>
            {state.selectedMode === "Bank" && (
              <>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Bank Name"
                  name="selectedBank"
                  value={state.selectedBank}
                  onChange={handleChange}
                  sx={{ mb: 2 }}>
                  {state.uniBanksArr.map((option) => (
                    <MenuItem key={option} value={option}>
                      {`${option}`}
                    </MenuItem>
                  ))}
                </CustomField>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Select Account"
                  name="selectedAcc"
                  value={state.selectedAcc}
                  onChange={handleChange}
                  sx={{ mb: 2 }}>
                  {state.accountsArr.map((option, id) => (
                    <MenuItem
                      key={option.account_number}
                      value={option.address}>
                      {`${option.address} [${option.account_number}]`}
                    </MenuItem>
                  ))}
                </CustomField>
              </>
            )}
            <CustomField
              fullWidth
              id="outlined-select-currency"
              label="Enter Amount"
              name="amount"
              value={state.amount}
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
            <Stack spacing={3} direction={"row"} sx={{ mt: 2 }}>
              <Button
                size="small"
                onClick={savePayment}
                sx={{
                  width: "250px",
                  textTransform: "none",
                  background: "#52b335",
                  color: "#FFF",
                  "&:hover": {
                    background: "#61D140",
                  },
                }}>
                {state.check ? "update payment" : " save payment"}
              </Button>
              <Button
                onClick={handleModal}
                size="small"
                sx={{
                  background: "#FFF",
                  width: "250px",
                  textTransform: "none",

                  color: "#9F9E9E",
                  "&:hover": {
                    background: "#EFEFEF",
                  },
                }}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        }
      />
    </Stack>
  );
};

export default Payment;
