import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
// import UserRepository from "../../Repository/UserRepository";

function Address({ user, onSuccess, onClose }) {
  const [state, setState] = useState({
    address: {
      street: "",
      city: "",
      terms: "",
      selectedPhone: "",
      phoneOne: "",
      phoneTwo: "",
    },
  });

  useLayoutEffect(() => {
    let address = {
      street: user?.address?.street || "",
      city: user?.address?.city || "",
      terms: user?.address?.terms || "",
      selectedPhone: user?.address?.selectedPhone || "",
      phoneOne: user?.address?.phoneOne || "",
      phoneTwo: user?.address?.phoneTwo || "",
    };
    setState((prev) => ({ ...prev, address }));
  }, []);

  const handleInput = (e) => {
    const { value, name } = e.target;
    setState((prev) => ({
      ...prev,
      address: { ...prev.address, [name]: value },
    }));
  };
  const handleUpdate = async () => {
    onClose();
    try {
      // let { data } = await UserRepository.usersModel({
      //   request: {
      //     method: "updateUser",
      //     data: {
      //       _id: user._id,
      //       user: {
      //         ...user,
      //         address: state.address,
      //       },
      //     },
      //   },
      // });
    } catch (err) {
      console.log("err", err);
      onSuccess();
    }
    onSuccess();
  };
  return (
    <Stack gap={2}>
      <Typography variant="h6" sx={{ color: "#4EAF51" }}>
        Enter Shipping Details!
      </Typography>

      <TextField
        type="text"
        size="small"
        name="street"
        label="Street Address"
        value={state.address?.street}
        fullWidth
        onChange={handleInput}
      />

      <TextField
        label="City"
        size="small"
        name="city"
        value={state.address?.city}
        type="text"
        fullWidth
        onChange={handleInput}
      />

      <TextField
        size="small"
        label="Terms and Conditions"
        name="terms"
        value={state.address?.terms}
        type="text"
        fullWidth
        onChange={handleInput}
      />

      <FormControl size="small">
        <FormLabel>Select Phone Number</FormLabel>
        <RadioGroup
          row
          name="selectedPhone"
          value={state.address?.selectedPhone}
          onChange={handleInput}>
          <FormControlLabel
            size="small"
            value="phoneOne"
            control={<Radio />}
            label="Phone 1"
          />
          <FormControlLabel
            size="small"
            value="phoneTwo"
            control={<Radio />}
            label="Phone 2"
          />
          <FormControlLabel
            size="small"
            value="bothNumbers"
            control={<Radio />}
            label="Both"
          />
        </RadioGroup>
      </FormControl>

      <TextField
        size="small"
        label="Phone 1"
        type="Number"
        name="phoneOne"
        value={state.address?.phoneOne}
        fullWidth
        onChange={handleInput}
      />
      <TextField
        size="small"
        label="Phone 2"
        name="phoneTwo"
        type="Number"
        value={state.address?.phoneTwo}
        fullWidth
        onChange={handleInput}
      />

      <Stack direction="row" justifyContent="space-evenly" spacing={4}>
        <Button fullWidth variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button fullWidth variant="contained" onClick={handleUpdate}>
          Save
        </Button>
      </Stack>
    </Stack>
  );
}

export default Address;
