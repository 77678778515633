import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import CompanyInfo from "./CompanyInfo";
import { getEmployeesZRTraders } from "../../apiservices";
import EmployeesList from "./EmployeesList";
const rowStyle = {
  color: "#4CAF50",
  backgroundColor: "#4CAF50",
  border: "none",
  height: "2px",
};

function CompanyProfile() {
  const [state, setState] = useState({
    employees: [],
  });
  const getEmployees = async () => {
    let obj = {
      company_code: "3378",
    };
    let res = await getEmployeesZRTraders(obj);
    setState((prev) => ({ ...prev, employees: res.employees }));
  };
  useEffect(() => {
    getEmployees();
  }, []);

  return (
    <>
      <CompanyInfo />
    </>
  );
}

export default CompanyProfile;
