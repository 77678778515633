import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { Layout } from "../dashboard/dashboard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataTable from "./StockTable";
import ReplyIcon from "@mui/icons-material/Reply";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Back } from "../../assets/Svg";
import PrintIcon from "@mui/icons-material/Print";
import Autocomplete from "@mui/material/Autocomplete";
import {
  getProduct,
  getProvider,
  getStockNew,
  saveStockNew,
  updateStockNew,
} from "../../apiservices";
import { toast } from "react-toastify";
import { Typo } from "../payment";
import CustomDialogue from "../reuseableComp/Modal";
import SimpleTable from "./SimpleTable";
import Search from "../searchBar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import StockPreview from "./stockPreview";
import ReactToPrint from "react-to-print";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#52b335",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const Btn = styled(Button)({
  height: "36px",
  padding: "4px",
  borderRadius: "4px",
  textTransform: "none",
  background: "#FFFFFF",
  marginRight: 10,
  color: "#52b335",
  "&:hover": {
    background: "#F6F6F6",
  },
  "&:active": {
    background: "#FFFFFF",
  },
});

const CustomField = styled(TextField)({
  mb: 2,
  background: "#F6F6F6",
  // width: "40%",
  borderRadius: "10px",
  boxShadow: "rgb(77, 175, 81) 0px 3px 7px",
  "& label.Mui-focused": {
    color: "#52b335",
  },
});
const CustomLayout = styled(Stack)({
  borderRadius: "13px",
  border: "1px solid #CFCBCB",
  background: "#FFF",
});

const Stock = () => {
  const theme = useTheme();
  const componentRef = useRef();
  const inputRef = useRef(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let subTotal = 0;
  const [state, setState] = useState({
    openModal: false,
    productData: [],
    stockData: [],
    providersData: [],
    productName: "",
    subTotal: "",
    model: "",
    salePrice: "",
    stock: "",
    check: false,
    updatedData: [],
    providerName: {},
    total: 0,
    detailModal: false,
    searchData: [],
    totalStock: 0,
    viewStock: false,
    stockTable: {},
    itemsArr: [],
    modelName: "",
    selectedModel: "",
  });
  const [newItems, setNewItems] = useState([]);
  const handleModal = (data = "") => {
    if (data?._id) {
      setState((prev) => ({
        ...prev,
        openModal: !prev.openModal,
        subTotal: "",
        model: "",
        salePrice: "",
        stock: "",
        check: false,
        updatedData: data,
        providerName: "",
      }));
      data?.items.forEach((data) => {
        let filtered = state.productData.filter(
          (item) => data.modelName === item.modelName
        );
        if (filtered) {
          return (data["currentStock"] = filtered[0].currentStock);
        }
      });
      setState((prev) => ({
        ...prev,
        total: data.totalPrice,
        itemsArr: data.saveStock
          ? [...data?.items, ...data?.saveStock[0].items]
          : data?.items,
        providerName: data,
        totalStock: data.totalStock,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        openModal: !prev.openModal,
        subTotal: "",
        model: "",
        salePrice: "",
        stock: "",
        check: false,
        updatedData: data,
        itemsArr: [],
        providerName: "",
      }));
      setNewItems([]);
    }
  };
  const removeFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  const getStocks = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    let obj = {
      company_code: userData.company_code,
    };
    let res = await getStockNew(obj);
    if (res) {
      setState((prev) => ({
        ...prev,
        stockData: res.stock.length !== 0 && res.stock,
        searchData: res.stock.length !== 0 && res.stock,
      }));
    }
  };
  const getProviders = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    let obj = {
      company_code: userData.company_code,
    };
    let res = await getProvider(obj);
    if (res) {
      setState((prev) => ({
        ...prev,
        providersData: res.items,
      }));
    }
  };
  const getProductData = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    let obj = {
      company_code: userData.company_code,
    };
    let res = await getProduct(obj);
    res.items.forEach((item, id) => {
      item["id"] = id + 1;
      item["name"] = item.modelName;
    });
    if (res) {
      setState((prev) => ({ ...prev, productData: res.items }));
    }
  };
  useEffect(() => {
    getProviders();
    getProductData();
    getStocks();
  }, []);
  const onSubmit = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (state.check) {
      if (state.providerName) {
        let totalStock = 0;
        [...state.itemsArr, ...newItems].map(
          (item) => (totalStock = totalStock + Number(item.quantity))
        );
        let totalAmount = 0;
        [...state.itemsArr, ...newItems].map(
          (item) => (totalAmount = totalAmount + Number(item.total))
        );
        let obj = {
          _id: state.updatedData._id,
          _rev: state.updatedData._rev,
          company_code: userData.company_code,
          totalPrice: totalAmount,
          totalStock: totalStock,
          providersName: state.providerName.providersName,
          items: state.itemsArr,
          saveStock: [
            {
              _id: state.updatedData._id,
              _rev: state.updatedData._rev,
              company_code: userData.company_code,
              totalPrice: totalAmount,
              totalStock: totalStock,
              providersName: state.providerName.providersName,
              items: newItems,
            },
          ],
        };
        let res = await updateStockNew(obj);
        if (res) {
          toast.success("stock updated successfully");
          getStocks();
          handleModal();
        }
      } else {
        toast.error("please fill all fields");
      }
    } else {
      if (state.providerName) {
        let totalStock = 0;
        newItems.map(
          (item) => (totalStock = totalStock + Number(item.quantity))
        );
        let totalAmount = 0;
        newItems.map(
          (item) => (totalAmount = totalAmount + Number(item.total))
        );
        let obj = {
          company_code: userData.company_code,
          totalPrice: totalAmount,
          totalStock: totalStock,
          providersName: state.providerName.providersName,
          items: newItems,
        };
        let res = await saveStockNew(obj);
        if (res) {
          toast.success("stock saved successfully");
          getStocks();
          handleModal();
        }
      } else {
        toast.error("please fill all fields");
      }
    }
  };
  const handleCheck = () => {
    setState((prev) => ({ ...prev, check: !prev.check }));
  };
  const handleFeaturesChange = (e, id) => {
    let { value } = e.target;
    let { name } = e.target;
    let arr = [...state.itemsArr];
    arr.forEach((element) => {
      if (element.id === id) {
        element[name] = value;
      }
    });

    if (name === "price" || name === "quantity") {
      arr.forEach((element) => {
        if (element.id === id) {
          element["total"] = element.quantity * element.price;
        }
        subTotal = subTotal + element.total;
      });
    }
    setState((prev) => ({ ...prev, itemsArr: arr }));
  };
  const handleNewFeatures = (e, id) => {
    let { value } = e.target;
    let { name } = e.target;
    let arr = [...newItems];
    newItems.map((element) => {
      if (element.id === id) {
        element[name] = value;
      }
    });

    if (name === "price" || name === "quantity") {
      newItems.map((element) => {
        if (element.id === id) {
          element["total"] = element.quantity * element.price;
        }
        subTotal = subTotal + element.total;
      });
    }
    getTotal();
    getSubTotal();
    setNewItems(arr);
    // setNewItems((prev) => [...prev, ...arr]);
    // setState((prev) => ({ ...prev, itemsArr: arr }));
  };
  const getSubTotal = () => {
    let total = 0;
    [...state.itemsArr, ...newItems].forEach((item) => {
      total += parseInt(item.quantity) || 0;
    });
    return total;
  };
  const getTotal = () => {
    let total = 0;
    [...state.itemsArr, ...newItems].forEach((item) => {
      total += parseInt(item.quantity) * parseInt(item.price);
    });
    return total;
  };
  const deleteItem = (id) => {
    let arr = state.itemsArr;
    let newArr = newItems;
    let filtered = arr.filter((element) => element.id !== id);
    let filterNew = newArr.filter((element) => element.id !== id);
    setState((prev) => ({ ...prev, itemsArr: filtered }));
    setNewItems(filterNew);
  };
  const openDetail = () => {
    setState((prev) => ({ ...prev, detailModal: !prev.detailModal }));
  };
  const updateData = (data) => {
    setState((prev) => ({ ...prev, stockData: data }));
  };
  const viewStock = (data = "") => {
    setState((prev) => ({
      ...prev,
      viewStock: !prev.viewStock,
      stockTable: data,
      tableArr: data.saveStock
        ? [...data?.items, ...data?.saveStock[0].items]
        : data?.items,
    }));
  };
  const ComponentToPrint = React.forwardRef((props, ref) => (
    <Box spacing={3} sx={{ mt: 2 }} ref={ref}>
      <Stack>
        <StockPreview data={state.stockTable} tableArr={state.tableArr} />
      </Stack>
    </Box>
  ));
  const handleOptionChange = (event, newOption, id) => {
    if (id) {
      removeFocus();
    }
    let subTotal = 0;
    let total = Number(state.prevBalance);
    if (newOption) {
      setState((prev) => ({
        ...prev,
        modelName: newOption.modelName,
        modelSearch: newOption.modelName,
      }));
    } else {
      let array = [...newItems, ...state.itemsArr];
      array.map((item) => {
        if (item.id === id) {
          item["modelName"] = "";
          item["itemName"] = 0;
          item["currentStock"] = 0;
          item["salePrice"] = 0;
          item["quantity"] = 0;
          item["price"] = 0;
          item["total"] = 0;
        }
        setState((prev) => ({
          ...prev,
          subTotal: 0,
          balance: item.qty * item.price,
          total: item.qty * item.price,
        }));
      });
      setState((prev) => ({
        ...prev,
        modelName: "",
        modelSearch: "",
      }));
    }
    let filtered = state.productData.filter(
      (item) => item?.modelName === newOption?.modelName
    );
    let array = [...newItems, ...state.itemsArr];
    newItems.map((item) => {
      if (filtered.length !== 0) {
        if (item.id === id) {
          item["modelName"] = filtered[0].modelName;
          item["_id"] = filtered[0]._id;
          // item["_rev"] = filtered[0]._rev;
          item["items"] = filtered[0].currentStock;
          item["price"] = 0;
          item["costPrice"] = filtered[0].costPrice;
          item["salePrice"] = filtered[0].salePrice;
          item["qty"] = 0;
          item["subTotal"] = 0;
        }
      }
      subTotal += item.qty * item.price;
    });
    setState((prev) => ({
      ...prev,
      subTotal: subTotal,
      balance: subTotal + total,
      total: subTotal + total,
    }));
  };
  const handleInputChange = (event, newInputValue, id) => {
    if (id) {
      removeFocus();
    }
    let subTotal = 0;
    let total = Number(state.prevBalance);
    if (newInputValue != "undefined") {
      if (newInputValue) {
        setState((prev) => ({ ...prev, modelSearch: newInputValue }));
      } else {
        setState((prev) => ({ ...prev, modelSearch: "" }));
      }
      let filtered = state.productData.filter(
        (item) => item.modelName === newInputValue
      );
      let array = [...newItems, ...state.itemsArr];

      array.map((item) => {
        if (filtered.length !== 0) {
          if (item.id === id) {
            // item["modelName"] = filtered[0].modelName;
            // item["items"] = 0;
            // item["price"] = 0;
            // item["qty"] = 0;
            // item["costPrice"] = filtered[0].costPrice;
            // item["salePrice"] = filtered[0].salePrice;
            // item["subTotal"] = 0;
          }
        }
        subTotal += item.qty * item.price;
      });
      setState((prev) => ({
        ...prev,
        subTotal: subTotal,
        balance: subTotal + total,
        total: subTotal + total,
      }));
    }
  };
  const handleProviderChange = (e, value) => {
    if (value) {
      setState((prev) => ({
        ...prev,
        providerName: value,
      }));
    }
  };
  const handleModelChange = (event, newOption) => {
    removeFocus();
    let subTotal = 0;
    let total = Number(state.prevBalance);
    if (newOption) {
      setState((prev) => ({
        ...prev,
        modelName: newOption.modelName,
        modelSearch: newOption.modelName,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        modelName: "",
        modelSearch: "",
      }));
    }
    let filtered = state.productData.filter(
      (item) => item?.modelName === newOption?.modelName
    );
    let array = [...newItems, ...state.itemsArr];

    if (filtered.length !== 0) {
      let obj = {
        id: new Date(),
        itemName: filtered[0].itemName,
        modelName: filtered[0].modelName,
        currentStock: filtered[0].currentStock,
        salePrice: filtered[0].salePrice,
        _id: filtered[0]._id,
        quantity: 0,
        price: 0,
        total: 0,
      };

      array.push(obj);
      setNewItems((prev) => [...prev, obj]);
    }
    array.map((item) => {
      subTotal += item.qty * item.price;
    });
    setState((prev) => ({
      ...prev,
      subTotal: subTotal,
      balance: subTotal + total,
      total: subTotal + total,
      modelName: "",
      selectedModel: "",
    }));
  };
  const handleOnSearch = (string) => {
    setState((prev) => ({ ...prev, modelName: string }));
  };
  const handleOnSelect = (data) => {
    let subTotal = 0;
    let total = Number(state.prevBalance);
    setState((prev) => ({ ...prev, modelName: data.modelName }));
    let filtered = state.productData.filter(
      (item) => item?.modelName === data?.modelName
    );
    let array = [...newItems, ...state.itemsArr];

    if (filtered.length !== 0) {
      let obj = {
        id: new Date(),
        itemName: filtered[0].itemName,
        modelName: filtered[0].modelName,
        currentStock: filtered[0].currentStock,
        salePrice: filtered[0].salePrice,
        _id: filtered[0]._id,
        quantity: 0,
        price: 0,
        total: 0,
      };

      array.push(obj);
      setNewItems((prev) => [...prev, obj]);
    }
    array.map((item) => {
      subTotal += item.qty * item.price;
    });
    setState((prev) => ({
      ...prev,
      subTotal: subTotal,
      balance: subTotal + total,
      total: subTotal + total,
      modelName: "",
      selectedModel: "",
    }));
  };
  return (
    <Stack sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <>
        {state.viewStock ? (
          <>
            <Layout
              justifyContent="end"
              alignItems={"center"}
              direction={"row"}>
              <Stack direction={"row"}>
                <ReactToPrint
                  trigger={() => (
                    <Btn>
                      <PictureAsPdfIcon fontSize="small" sx={{ mx: 1 }} />{" "}
                      {!fullScreen && "PDF"}
                    </Btn>
                  )}
                  content={() => componentRef.current}
                />

                <ReactToPrint
                  trigger={() => (
                    <Btn>
                      <PrintIcon fontSize="small" sx={{ mx: 1 }} />{" "}
                      {!fullScreen && "Print"}
                    </Btn>
                  )}
                  content={() => componentRef.current}
                />
                <Btn onClick={viewStock}>
                  <Back sx={{ mx: 1 }} /> {!fullScreen && "Back"}
                </Btn>
              </Stack>
            </Layout>
            <Stack id="content-to-print">
              <ComponentToPrint ref={componentRef} />
            </Stack>
          </>
        ) : state.openModal ? (
          <>
            <Layout
              justifyContent="space-between"
              alignItems={"center"}
              direction={"row"}>
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "24px",
                  fontWeight: 700,
                }}
              />
              <Typography
                sx={{ color: "#FFF", fontSize: "24px", fontWeight: 700 }}>
                Items
              </Typography>
              <Btn onClick={handleModal}>
                <ReplyIcon fontSize="small" sx={{ mx: 1 }} /> Back
              </Btn>
            </Layout>

            <Stack sx={{ padding: 1 }}>
              <Stack justifyContent={"start"}>
                <Autocomplete
                  size="small"
                  options={state.providersData}
                  fullWidth
                  value={state.providerName}
                  onChange={(e, value) => handleProviderChange(e, value)}
                  getOptionLabel={(option) =>
                    `${option?.providersName} (${option.companyName})` || ""
                  }
                  sx={{ mb: 2, width: "40%" }}
                  renderInput={(params) => (
                    <CustomField
                      {...params}
                      label="Select or Type provider name"
                      variant="outlined"
                      // InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Stack>
              <CustomLayout sx={{ padding: 1 }}>
                <TableContainer>
                  <Table sx={{ width: "100%" }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>SR#</StyledTableCell>
                        <StyledTableCell align="left">
                          Model Name
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Sale Price
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Current Stock
                        </StyledTableCell>
                        <StyledTableCell align="left">Quantity</StyledTableCell>
                        <StyledTableCell align="left">Price</StyledTableCell>
                        <StyledTableCell align="left">Total</StyledTableCell>
                        <StyledTableCell align="left">Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.itemsArr.map((item, id) => (
                        <TableRow key={1}>
                          <StyledTableCell>
                            <Typography>{id + 1}</Typography>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Autocomplete
                              autoFocus
                              size="small"
                              options={state.productData}
                              fullWidth
                              value={item.modelName}
                              // inputValue={state.modelSearch}
                              onChange={(e, value) =>
                                handleOptionChange(e, value, item.id)
                              }
                              onInputChange={(e, value) =>
                                handleInputChange(e, value, item.id)
                              }
                              getOptionLabel={(option) =>
                                option?.modelName ? option?.modelName : option
                              }
                              renderInput={(params) => (
                                <CustomField
                                  {...params}
                                  label="Select or Type item name"
                                  variant="outlined"
                                  inputRef={inputRef}
                                  // InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CustomField
                              size="small"
                              fullWidth
                              value={item.salePrice}
                              disabled
                              name="salePrice"
                              label="Sale Price"
                            />
                          </StyledTableCell>

                          <StyledTableCell>
                            <CustomField
                              size="small"
                              fullWidth
                              value={item.currentStock}
                              name="currentStock"
                              disabled
                              label="Current Stock"
                              onChange={(e) => handleFeaturesChange(e, item.id)}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CustomField
                              fullWidth
                              size="small"
                              value={item.quantity}
                              name="quantity"
                              label="Quantity"
                              onChange={(e) => handleFeaturesChange(e, item.id)}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CustomField
                              fullWidth
                              size="small"
                              value={item.price}
                              name="price"
                              label="Price"
                              onChange={(e) => handleFeaturesChange(e, item.id)}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CustomField
                              fullWidth
                              size="small"
                              value={item.total}
                              name="price"
                              label="Total"
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            direction={"row"}
                            alignItems={"center"}>
                            <strong style={{ color: "red" }}>
                              <DeleteIcon
                                onClick={() => deleteItem(item.id)}
                                fontSize="small"
                              />
                              {/* You can add more buttons or customize based on your requirements */}
                            </strong>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                      {newItems.map((item, id) => (
                        <TableRow key={1}>
                          <StyledTableCell>
                            <Typography>{id + 1}</Typography>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Autocomplete
                              autoFocus
                              size="small"
                              options={state.productData}
                              fullWidth
                              value={item.modelName}
                              // inputValue={state.modelSearch}
                              onChange={(e, value) =>
                                handleOptionChange(e, value, item.id)
                              }
                              onInputChange={(e, value) =>
                                handleInputChange(e, value, item.id)
                              }
                              getOptionLabel={(option) =>
                                option?.modelName ? option?.modelName : option
                              }
                              renderInput={(params) => (
                                <CustomField
                                  {...params}
                                  label="Select or Type item name"
                                  variant="outlined"
                                  inputRef={inputRef}
                                  // InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CustomField
                              size="small"
                              fullWidth
                              value={item.salePrice}
                              disabled
                              name="salePrice"
                              label="Sale Price"
                            />
                          </StyledTableCell>

                          <StyledTableCell>
                            <CustomField
                              size="small"
                              fullWidth
                              value={item.currentStock}
                              name="currentStock"
                              disabled
                              label="Current Stock"
                              onChange={(e) => handleNewFeatures(e, item.id)}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CustomField
                              fullWidth
                              size="small"
                              value={item.quantity}
                              name="quantity"
                              label="Quantity"
                              onChange={(e) => handleNewFeatures(e, item.id)}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CustomField
                              fullWidth
                              size="small"
                              value={item.price}
                              name="price"
                              label="Price"
                              onChange={(e) => handleNewFeatures(e, item.id)}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CustomField
                              fullWidth
                              size="small"
                              value={item.total}
                              name="price"
                              label="Total"
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            direction={"row"}
                            alignItems={"center"}>
                            <strong style={{ color: "red" }}>
                              <DeleteIcon
                                onClick={() => deleteItem(item.id)}
                                fontSize="small"
                              />
                              {/* You can add more buttons or customize based on your requirements */}
                            </strong>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                      <TableRow key={1}>
                        <StyledTableCell>
                          <Typography></Typography>
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: "30%", zIndex: 9999 }}>
                          {/* <ReactSearchAutocomplete
                            styling={{
                              mb: 2,
                              background: "#F6F6F6",
                              borderRadius: "10px",
                              boxShadow: "rgb(77, 175, 81) 0px 3px 7px",
                              zIndex: 9999,
                            }}
                            items={state.productData}
                            onSearch={handleOnSearch}
                            onSelect={handleOnSelect}
                            autoFocus
                            inputSearchString={state.modelName}
                            showItemsOnFocus={true}
                          /> */}
                          <Autocomplete
                            size="small"
                            options={state.productData}
                            fullWidth
                            value={state.modelName}
                            inputValue={state.modelSearch}
                            onChange={(e, value) => handleModelChange(e, value)}
                            onInputChange={(e, value) =>
                              handleInputChange(e, value)
                            }
                            getOptionLabel={(option) =>
                              option?.modelName ? option?.modelName : option
                            }
                            renderInput={(params) => (
                              <CustomField
                                {...params}
                                label="Select or Type item name"
                                variant="outlined"
                                // InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <CustomField
                            size="small"
                            fullWidth
                            disabled
                            value={0}
                            name="salePrice"
                            label="General Sale Price"
                          />
                        </StyledTableCell>

                        <StyledTableCell>
                          <CustomField
                            size="small"
                            fullWidth
                            value={0}
                            disabled
                            name="currentStock"
                            label="Current Stock"
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <CustomField
                            fullWidth
                            size="small"
                            value={0}
                            disabled
                            name="quantity"
                            label="Quantity"
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <CustomField
                            fullWidth
                            disabled
                            size="small"
                            value={0}
                            name="price"
                            label="Price"
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <CustomField
                            fullWidth
                            disabled
                            size="small"
                            name="price"
                            value={0}
                            label="Total"
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          direction={"row"}
                          alignItems={"center"}>
                          <strong style={{ color: "red" }}></strong>
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CustomLayout>
              <Stack
                direction={fullScreen ? "column" : "row"}
                justifyContent={"space-around"}
                alignItems={"center"}
                spacing={2}
                sx={{
                  borderRadius: "8px",
                  border: "0px 0px 1px 0px",
                  background: "rgb(245, 255, 246)",
                  width: "98%",
                  mt: 1,
                  padding: "10px",
                  boxShadow: "2px 2px 2px 2px rgb(245, 255, 246)",
                }}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}></Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    name="subTotal"
                    label="Total Stock"
                    value={getSubTotal()}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    disabled
                  />
                </Stack>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  name="subTotal"
                  label="Total Amount"
                  value={getTotal()}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                  disabled
                />
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  spacing={2}>
                  <Button
                    onClick={onSubmit}
                    sx={{
                      width: "200px",
                      textTransform: "none",
                      background: "#52b335",
                      color: "#FFF",
                      "&:hover": {
                        background: "#61D140",
                      },
                    }}>
                    save
                  </Button>
                  <Button
                    onClick={handleModal}
                    sx={{
                      width: "200px",
                      background: "red",
                      textTransform: "none",

                      color: "#FFF",
                      "&:hover": {
                        background: "red",
                      },
                    }}>
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </>
        ) : (
          <>
            <Layout
              justifyContent="space-between"
              alignItems={"center"}
              direction={"row"}>
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "24px",
                  fontWeight: 700,
                }}
              />
              <Typography
                sx={{ color: "#FFF", fontSize: "24px", fontWeight: 700 }}>
                Stock
              </Typography>
              <Btn onClick={handleModal}>
                <AddCircleIcon fontSize="small" sx={{ mx: 1 }} /> Add new stock
              </Btn>
            </Layout>
            <Stack sx={{ padding: "20px" }}>
              <Stack
                justifyContent={"start"}
                alignItems={"start"}
                sx={{
                  width: "50%",
                }}>
                <Search
                  data={state.searchData}
                  updateData={(data) => updateData(data)}
                  place1={" Search by provider name"}
                  place2={""}
                />
              </Stack>
              <DataTable
                editStock={(data) => handleModal(data)}
                data={state.stockData}
                handleCheck={handleCheck}
                getData={getStocks}
                openDetail={openDetail}
                view={(data) => viewStock(data)}
                providersData={state.providersData}
              />
            </Stack>
          </>
        )}
      </>
      <CustomDialogue
        open={state.detailModal}
        handleClose={openDetail}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typo>Summary</Typo>
          </Stack>
        }
        content={
          <Stack sx={{ padding: 1 }}>
            <Stack sx={{ mt: 2 }}>
              <SimpleTable />
            </Stack>
          </Stack>
        }
      />
    </Stack>
  );
};

export default Stock;
