import React, { useEffect, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import SaleTable from "./SaleTable";
import PaymentTable from "./PaymentTable";
import {
  getBills,
  getPayment,
  getPaymentByRange,
  getRangeSalesByCompanyZRT,
} from "../../apiservices";
import { Layout } from "../dashboard/dashboard";
import { Btn } from "../payment";
import { Back } from "../../assets/Svg";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactToPrint from "react-to-print";
import { handleSelectedCom } from "../../sidebar/toggleSlice";
import { useDispatch } from "react-redux";
import moment from "moment";
function TransactionComp() {
  const componentRef = React.useRef();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    saleData: [],
    paymentData: [],
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const getBillData = async () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    let obj = {
      company_code: userData.company_code,
      startDate: moment(state.startDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
      endDate: moment(state.endDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
    };
    let res = await getRangeSalesByCompanyZRT(obj);
    setState((prev) => ({
      ...prev,
      saleData: res,
    }));
  };
  const getPayments = async () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    let obj = {
      company_code: userData.company_code,
      startDate: moment(state.startDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
      endDate: moment(state.endDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
    };
    let res = await getPaymentByRange(obj);
    if (res) {
      setState((prev) => ({
        ...prev,
        paymentData: res,
      }));
    }
  };
  useEffect(() => {
    getBillData();
    getPayments();
  }, [state.startDate, state.endDate]);
  const printToPDF = () => {
    // Select the content you want to print to PDF by its ID or class
    const contentToPrint = document.getElementById("content-to-print");

    // Use html2canvas to capture the content as an image
    html2canvas(contentToPrint).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Set dimensions for the PDF
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210; // A4 size
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add the captured image to the PDF
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

      // Download the PDF
      pdf.save("content.pdf");
    });
  };
  const handleSelection = (arg) => {
    dispatch(handleSelectedCom(arg));
  };
  const handleChange = (e) => {
    let { value } = e.target;
    let { name } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const ComponentToPrint = React.forwardRef((props, ref) => (
    <>
      <Stack
        direction={"row"}
        sx={{ padding: "10px" }}
        justifyContent={"start"}
        alignItems={"center"}
        spacing={2}>
        <Stack
          sx={{
            width: "25%",
            background: "#F6F6F6",
            borderRadius: "10px",
            boxShadow: "rgb(77, 175, 81) 0px 2px 5px",
            padding: 1,
          }}>
          <label>Start Date</label>
          <input
            style={{ padding: "10px", borderRadius: "6px" }}
            type="date"
            value={state.startDate}
            name="startDate"
            onChange={handleChange}
          />
        </Stack>
        <Typography sx={{ fontSize: "18px" }}>To</Typography>
        <Stack
          sx={{
            width: "25%",
            background: "#F6F6F6",
            borderRadius: "10px",
            boxShadow: "rgb(77, 175, 81) 0px 2px 5px",
            padding: 1,
          }}>
          <label>End Date</label>
          <input
            style={{ padding: "10px", borderRadius: "6px" }}
            type="date"
            value={state.endDate}
            name="endDate"
            onChange={handleChange}
          />
        </Stack>
      </Stack>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        ref={ref}
        id="content-to-print">
        <Grid
          container
          sx={{ mt: 3, display: "flex", justifyContent: "center" }}
          spacing={2}>
          <Grid item={6}>
            <Stack
              justifyContent={"space-around"}
              alignItems={"center"}
              direction={"row"}>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: 1 }}>
                Sales
              </Typography>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: 1 }}>
                Total : {state.saleData?.totalSales}
              </Typography>
            </Stack>
            <SaleTable data={state.saleData.bills} />
          </Grid>
          <Grid item={6}>
            <Stack
              justifyContent={"space-around"}
              alignItems={"center"}
              direction={"row"}>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: 1 }}>
                Payments
              </Typography>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: 1 }}>
                Total : {state.paymentData?.totalPayments}
              </Typography>
            </Stack>
            <PaymentTable data={state.paymentData.payments} />
          </Grid>
        </Grid>
      </Stack>
    </>
  ));
  return (
    <>
      <Layout
        justifyContent="space-between"
        alignItems={"center"}
        direction={"row"}>
        <Typography></Typography>
        <Typography
          sx={{
            color: "#FFF",
            fontSize: "24px",
            fontWeight: 700,
            padding: 1,
          }}>
          Transactions
        </Typography>
        <Stack direction={"row"}>
          <ReactToPrint
            trigger={() => (
              <Btn>
                <PictureAsPdfIcon fontSize="small" sx={{ mx: 1 }} /> PDF
              </Btn>
            )}
            content={() => componentRef.current}
          />

          <ReactToPrint
            trigger={() => (
              <Btn>
                <PrintIcon fontSize="small" sx={{ mx: 1 }} /> Print
              </Btn>
            )}
            content={() => componentRef.current}
          />
          <Btn onClick={() => handleSelection("dashboard")}>
            <Back sx={{ mx: 1 }} /> Back
          </Btn>
        </Stack>
      </Layout>
      <ComponentToPrint ref={componentRef} />
    </>
  );
}

export default TransactionComp;
