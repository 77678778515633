import {
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
  Box,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CustomField } from "../client/index.jsx";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";
import {
  getClient,
  getClientById,
  getProduct,
  saveBills,
  saveSale,
  updateBillZkTraders,
  updateClientBalance,
  updateClientPaymentSale,
  updateItem,
  updateLedgerSalePayments,
} from "../../apiservices/index.jsx";
import moment from "moment/moment.js";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { handleSalesData } from "./saleSlice.jsx";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#52b335",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const Layout = styled(Stack)({
  width: "380px",
  borderRadius: "8px",
  border: "0px 0px 1px 0px",
  background: "#F2F5FA",
  padding: "10px",
  marginBottom: 30,
});
const Typo = styled(Typography)({
  color: "#323232",
  fontWeight: 600,
  // width: "200px",
  borderBottom: "1px",
});

export const CustomBtn = styled(Button)({
  textTransform: "none",
  width: "90px",
});
function AddSale({
  getTabelData,
  itemsArr,
  cancelSale,
  customData,
  rowData,
  getBillData,
}) {
  const bottomRef = useRef(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [newItems, setNewItems] = useState([]);
  const [state, setState] = useState({
    search: "",
    searchModal: false,
    searchArr: [],
    modelSearch: "",
    modelName: "",
    selectedModel: "",
    addItemsArr: itemsArr ? itemsArr : [],
    newAddItemArr: [],
    qty: 0,
    subTotal: customData.subTotal ? customData.subTotal : 0,
    discount: 0,
    disType: "price",
    total: customData.prev ? customData.prev : 0,
    paid: customData.paid ? customData.paid : 0,
    balance: 0,
    cName: customData.client ? customData.client : "",
    prevBalance: 0,
    clientData: [],
    selectedClient: customData.prev ? customData : {},
    clientNo: "",
    clientAdd: "",
    productData: [],
    addQty: 0,
  });
  const inputRef = useRef(null);
  let addArr = state.addItemsArr;
  const getClientsData = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    let obj = {
      company_code: userData.company_code,
    };
    let res = await getClient(obj);
    if (res) {
      setState((prev) => ({
        ...prev,
        clientData: res.client,
      }));
      if (customData?.clientName) {
        let filtered = res.client.filter(
          (item) => item.clientName == customData?.clientName
        );
        if (filtered) {
          setState((prev) => ({
            ...prev,
            prevBalance: filtered[0]?.currentBalance,
            total: filtered[0]?.currentBalance,
            balance: filtered[0]?.currentBalance,
          }));
        }
      }
    }
  };
  useEffect(() => {
    getClientsData();
    if (bottomRef.current) {
      bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
    }
  }, []);
  const handleChange = (e, id = "") => {
    let subTotal = 0;
    let total = Number(state.prevBalance);
    let { value } = e.target;
    let { name } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
    if (name === "disType") {
      setState((prev) => ({ ...prev, discount: "", total: "" }));
    } else if (name === "prevBalance") {
      let finalPrice = state.subTotal + Number(value);
      setState((prev) => ({ ...prev, balance: finalPrice, total: finalPrice }));
    } else if (name === "paid") {
      let finalPrice = state.total - Number(value);
      setState((prev) => ({ ...prev, balance: finalPrice }));
    } else if (name === "selectedClient") {
      let filtered = state.clientData.filter(
        (item) => item.clientName === value
      );
      setState((prev) => ({
        ...prev,
        cName: value,
        selectedClient: value,
        clientAdd: value.address,
        clientNo: value.mobileNumber,
        prevBalance: filtered[0].currentBalance,
        total: Number(filtered[0].openingBalance) + Number(prev.subTotal),
        balance: Number(filtered[0].openingBalance) + Number(prev.subTotal),
      }));
    } else if (name === "modelName") {
      let filtered = state.productData.filter(
        (item) => item.modelName === value
      );
      let array = state.addItemsArr;
      array.map((item) => {
        setState((prev) => ({
          ...prev,
          subTotal: item.qty * item.price + subTotal,
          balance: item.qty * item.price + total,
          total: item.qty * item.price + total,
        }));
        if (item.id === id) {
          item["modelName"] = filtered[0].modelName;
          item["items"] = filtered[0].currentStock;
          item["price"] = filtered[0].salePrice;
          item["qty"] = 0;
          item["subTotal"] = 0;
        }
      });
    }
  };
  const makeBill = async () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    if (state.subTotal) {
      let filtered = state.clientData.filter(
        (item) => item.clientName === customData.clientName
      );
      if (customData?._id) {
        let obj = {
          _id: customData?._id,
          _rev: customData?._rev,
          company_code: customData.company_code,
          items: state.addItemsArr,
          billNumber: customData.billNumber,
          subTotal: state.subTotal,
          prev: state.prevBalance,
          total: state.total,
          paid: state.paid,
          amount: state.subTotal,
          balance: state.balance,
          saveSale: newItems,
        };

        let updateObj = {
          _id: filtered && filtered[0]?._id,
          type: "sale",
          paid: state.subTotal,
        };
        let updateLedgerSale = {
          _id: filtered[0]?._id,
          clientEntry: {
            srNum: customData?._id,
            amount: state.subTotal,
            billNumber: customData.billNumber,
            date: moment().valueOf(),
            paid: state.paid,
            type: "sale",
          },
        };
        let res = await updateBillZkTraders(obj);
        await updateClientBalance(updateLedgerSale);
        getClientsData();
        if (res) {
          let obj = {
            _id: filtered && filtered[0]?._id,
          };
          await getBillData();
          toast.success("Bill updated successfully");
          await updateClientPaymentSale(updateObj);
          await getClientById(obj);

          cancelSale();
        }
      } else {
        let filtered = state.clientData.filter(
          (item) => item.clientName === state.selectedClient.clientName
        );
        let obj = {
          company_code: userData.company_code,
          items: newItems,
          billNumber:
            rowData.length > 8
              ? `ZR_0${rowData.length + 1}`
              : `ZR_00${rowData.length + 1}`,
          seller: userData.name,
          client: state.selectedClient.clientName,
          clientAdd: state.clientAdd,
          clientNo: state.clientNo,
          date: moment().valueOf(),
          amount: state.subTotal,
          subTotal: state.subTotal,
          prev: state.prevBalance,
          total: state.total,
          paid: state.paid,
          clientId: state.selectedClient._id,
          balance: state.balance,
          city: state.selectedClient.city,
        };
        let saleObj = newItems.map((item) => ({
          ...item,
          ["company_code"]: userData.company_code,
        }));

        let updateObj = {
          _id: filtered && filtered[0]?._id,
          type: "sale",
          paid: state.subTotal,
        };
        let res = await saveBills(obj);
        if (res) {
          let updateLedgerSale = {
            _id: state.selectedClient._id,
            clientArray: {
              srNum: res.bill,
              amount: state.subTotal,
              paid: state.paid,
              billNumber:
                rowData.length > 8
                  ? `ZR_0${rowData.length + 1}`
                  : `ZR_00${rowData.length + 1}`,
              date: moment().valueOf(),
              type: "sale",
            },
          };
          let obj = {
            _id: filtered && filtered[0]?._id,
          };
          getClientsData();

          getClientById(obj);
          await updateLedgerSalePayments(updateLedgerSale);
        }
        await saveSale(saleObj);
        await updateItem({ items: saleObj });
        cancelSale();
        getBillData();
        await updateClientPaymentSale(updateObj);
        getTabelData(newItems);

        dispatch(handleSalesData(obj));
      }
    } else {
      toast.error("please enter items ");
    }
  };
  const handleNewFeature = (e, id) => {
    let subTotal = 0;
    let { value, name } = e.target;
    let array = [...newItems, ...state.addItemsArr];
    newItems.map((element) => {
      if (element.id === id) {
        element[name] = value;
        element["salePrice"] = name === "price" ? value : element.price;
        element["subTotal"] = Number(element.price) * Number(element.qty);
      }
    });
    array.forEach((element) => {
      subTotal += element.subTotal;
    });
    setState((prev) => ({
      ...prev,
      subTotal: subTotal,
      balance: subTotal + Number(prev.prevBalance),
      total: subTotal + Number(prev.prevBalance),
    }));
    // setState((prev) => ({ ...prev, addItemsArr: arr }));
  };
  const handleFeaturesChange = (e, id) => {
    let subTotal = 0;
    let { value } = e.target;
    let { name } = e.target;
    let arr = state.addItemsArr;
    arr.map((element) => {
      if (element.id === id) {
        element[name] = value;
        element["salePrice"] = name === "price" ? value : element.price;
        element["subTotal"] = Number(element.price) * Number(element.qty);
      }
    });
    arr.forEach((element) => {
      subTotal += element.subTotal;
    });
    setState((prev) => ({
      ...prev,
      subTotal: subTotal,
      balance: subTotal + Number(prev.prevBalance),
      total: subTotal + Number(prev.prevBalance),
    }));
    setState((prev) => ({ ...prev, addItemsArr: arr }));
  };
  const getProducts = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    let obj = {
      company_code: userData.company_code,
    };
    let res = await getProduct(obj);
    if (res) {
      setState((prev) => ({
        ...prev,
        productData: res.items,
      }));
      let arr = addArr;
      arr?.forEach((data) => {
        let filtered = res.items.filter(
          (item) => data.modelName === item.modelName
        );
        if (filtered) {
          return (data["currentStock"] = filtered[0]?.currentStock);
        }
      });
    }
  };
  useEffect(() => {
    getProducts();
  }, [state.subTotal]);
  const deleteRow = async (params) => {
    let subTotal = 0;

    let arr = state.addItemsArr;
    let newArr = newItems;
    let filtered = arr.filter((element) => element.id !== params);
    let filterNew = newArr.filter((element) => element.id !== params);
    filtered.forEach((element) => {
      subTotal += element.subTotal;
    });
    filterNew.forEach((element) => {
      subTotal += element.subTotal;
    });
    setState((prev) => ({
      ...prev,
      subTotal: subTotal,
      balance: subTotal + Number(prev.prevBalance),
      total: subTotal + Number(prev.prevBalance),
    }));
    setNewItems(filterNew);
    setState((prev) => ({ ...prev, addItemsArr: filtered }));
  };
  const handleNewOption = (event, newOption, id) => {
    let subTotal = 0;
    let total = Number(state.prevBalance);
    if (newOption) {
      setState((prev) => ({
        ...prev,
        // modelName: newOption.modelName,
        modelSearch: newOption.modelName,
      }));
    } else {
      let array = state.addItemsArr;
      newItems.map((item) => {
        if (item.id === id) {
          item["modelName"] = "";
          item["items"] = "";
          item["price"] = 0;
          item["qty"] = 0;
          item["subTotal"] = 0;
          item["costPrice"] = 0;
        }
        setState((prev) => ({
          ...prev,
          subTotal: 0,
          balance: item.qty * item.price,
          total: item.qty * item.price,
        }));
      });
      setState((prev) => ({
        ...prev,
        modelName: "",
        modelSearch: "",
      }));
    }
    let filtered = state.productData.filter(
      (item) => item?.modelName === newOption?.modelName
    );
    let array = state.addItemsArr;
    let arr = state.newAddItemArr;
    array.map((item) => {
      if (filtered.length !== 0) {
        if (item.id === id) {
          item["modelName"] = filtered[0].modelName;
          item["_id"] = filtered[0]._id;
          // item["_rev"] = filtered[0]._rev;
          item["items"] = filtered[0].itemName;
          item["currentStock"] = filtered[0].currentStock;
          item["price"] = filtered[0].salePrice;
          item["costPrice"] = filtered[0].costPrice;
          item["salePrice"] = filtered[0].salePrice;
          item["qty"] = 0;
          item["subTotal"] = 0;
        }
      }
      subTotal += item.qty * item.price;
    });

    setState((prev) => ({
      ...prev,
      subTotal: subTotal,
      balance: subTotal + total,
      total: subTotal + total,
      newAddItemArr: arr,
    }));
  };
  const handleInputChange = (event, newInputValue, id) => {
    removeFocus();

    let subTotal = 0;
    let total = Number(state.prevBalance);
    if (newInputValue != "undefined") {
      if (newInputValue) {
        setState((prev) => ({ ...prev, modelSearch: newInputValue }));
      } else {
        setState((prev) => ({ ...prev, modelSearch: "" }));
      }
      let filtered = state.productData.filter(
        (item) => item.modelName === newInputValue
      );
      let array = state.addItemsArr;
      array.map((item) => {
        if (filtered.length !== 0) {
          if (item.id === id) {
            item["modelName"] = filtered[0].modelName;
            item["items"] = filtered[0].itemName;
            item["price"] = filtered[0].salePrice;
            item["qty"] = 0;
            item["costPrice"] = filtered[0].costPrice;
            item["salePrice"] = filtered[0].salePrice;
            item["currentStock"] = filtered[0].currentStock;
            item["subTotal"] = 0;
          }
        }
        subTotal += item.qty * item.price;
      });

      setState((prev) => ({
        ...prev,
        subTotal: subTotal,
        balance: subTotal + total,
        total: subTotal + total,
      }));
    }
  };
  const handleNewInput = (event, newInputValue, id) => {
    removeFocus();
    let subTotal = 0;
    let total = Number(state.prevBalance);
    if (newInputValue != "undefined") {
      if (newInputValue) {
        setState((prev) => ({ ...prev, modelSearch: newInputValue }));
      } else {
        setState((prev) => ({ ...prev, modelSearch: "" }));
      }
      let filtered = state.productData.filter(
        (item) => item.modelName === newInputValue
      );
      let array = [...newItems, ...state.addItemsArr];
      newItems.map((item) => {
        if (filtered.length !== 0) {
          if (item.id === id) {
            item["modelName"] = filtered[0].modelName;
            item["items"] = filtered[0].itemName;
            item["price"] = filtered[0].salePrice;
            item["qty"] = 0;
            item["costPrice"] = filtered[0].costPrice;
            item["salePrice"] = filtered[0].salePrice;
            item["currentStock"] = filtered[0].currentStock;
            item["subTotal"] = 0;
          }
        }
      });
      array.map((item) => {
        subTotal += item.qty * item.price;
      });
      setState((prev) => ({
        ...prev,
        subTotal: subTotal,
        balance: subTotal + total,
        total: subTotal + total,
      }));
    }
  };
  const handleIChange = (event, newInputValue, id) => {
    if (newInputValue != "undefined") {
      if (newInputValue) {
        setState((prev) => ({ ...prev, selectedModel: newInputValue }));
      } else {
        setState((prev) => ({ ...prev, selectedModel: "" }));
      }
    }
  };
  const removeFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  const handleModelChange = (event, newOption) => {
    removeFocus();
    let subTotal = 0;
    let total = Number(state.prevBalance);
    if (newOption) {
      setState((prev) => ({
        ...prev,
        modelName: newOption.modelName,
        modelSearch: newOption.modelName,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        modelName: "",
        modelSearch: "",
      }));
    }
    let filtered = state.productData.filter(
      (item) => item?.modelName === newOption?.modelName
    );
    let array = [...newItems, ...state.addItemsArr];
    if (filtered.length !== 0) {
      let obj = {
        id: new Date(),
        items: filtered[0].currentStock,
        modelName: filtered[0].modelName,
        qty: 0,
        price: filtered[0].salePrice,
        returnQty: 0,
        subTotal: 0,
        _id: filtered[0]._id,
      };

      array.push(obj);
      setNewItems((prev) => [...prev, obj]);
      getTabelData(array);
    }
    array.map((item) => {
      subTotal += item.qty * item.price;
    });
    setState((prev) => ({
      ...prev,
      subTotal: subTotal,
      balance: subTotal + total,
      total: subTotal + total,
      modelName: "",
      selectedModel: "",
    }));
  };
  const handleClientChange = (e, value) => {
    removeFocus();

    if (value) {
      setState((prev) => ({
        ...prev,
        selectedClient: value,
      }));
      let filtered = state.clientData.filter(
        (item) => item.clientName === value.clientName
      );
      setState((prev) => ({
        ...prev,
        cName: value,
        selectedClient: value,
        clientAdd: value.address,
        clientNo: value.mobileNumber,
        prevBalance: filtered[0].currentBalance,
        total: Number(filtered[0].openingBalance) + Number(prev.subTotal),
        balance: Number(filtered[0].openingBalance) + Number(prev.subTotal),
      }));
    }
  };
  return (
    <>
      <Grid container sx={{ padding: 1 }}>
        <Grid item spacing={2} sx={{ mx: 1, mb: 2 }}>
          <Typo>Select Client</Typo>
          <Layout>
            <Autocomplete
              size="small"
              options={state.clientData}
              fullWidth
              value={state.selectedClient}
              onChange={(e, value) => handleClientChange(e, value)}
              getOptionLabel={(option) =>
                option?.clientName || option.client || ""
              }
              sx={{ mb: 2 }}
              renderInput={(params) => (
                <CustomField
                  {...params}
                  label="Select or Type client name"
                  variant="outlined"
                  // InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Layout>
          <Box sx={{ maxHeight: "350px", overflowY: "auto" }}>
            <TableContainer>
              <Table sx={{ width: "100%" }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>SR#</StyledTableCell>
                    <StyledTableCell align="left">Item Name</StyledTableCell>
                    <StyledTableCell align="left">
                      Current Quantity{" "}
                    </StyledTableCell>
                    <StyledTableCell align="left">Quantity</StyledTableCell>
                    <StyledTableCell align="left">Price</StyledTableCell>
                    <StyledTableCell align="left">Sub Total</StyledTableCell>
                    <StyledTableCell align="left">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.addItemsArr.map((item, id) => (
                    <TableRow key={1}>
                      <StyledTableCell>
                        <Typography>{id + 1}</Typography>
                      </StyledTableCell>
                      <StyledTableCell sx={{ width: "30%" }}>
                        <Autocomplete
                          size="small"
                          options={state.productData}
                          fullWidth
                          value={item.modelName}
                          // inputValue={state.modelSearch}
                          onChange={(e, value) =>
                            handleNewOption(e, value, item.id)
                          }
                          onInputChange={(e, value) =>
                            handleInputChange(e, value, item.id)
                          }
                          getOptionLabel={(option) =>
                            option?.modelName ? option?.modelName : option
                          }
                          renderInput={(params) => (
                            <CustomField
                              {...params}
                              label="Select or Type brand name"
                              variant="outlined"
                              inputRef={inputRef}
                              // InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          type="number"
                          disabled
                          name="qty"
                          value={item.currentStock}
                          sx={{
                            background: "#FFF",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          type="number"
                          name="qty"
                          value={item.qty}
                          onChange={(e) => handleFeaturesChange(e, item.id)}
                          label="Add Quantity"
                          sx={{
                            background: "#FFF",
                          }}
                        />
                      </StyledTableCell>

                      <StyledTableCell>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          label="price"
                          type="number"
                          value={item.price}
                          name="price"
                          onChange={(e) => handleFeaturesChange(e, item.id)}
                          sx={{
                            background: "#FFF",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell sx={{ width: "15%" }}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          type="number"
                          disabled
                          name="qty"
                          value={item.subTotal}
                          sx={{
                            background: "#FFF",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <span
                          style={{ color: "red" }}
                          onClick={() => deleteRow(item.id)}>
                          <DeleteIcon />
                        </span>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                  {newItems.map((item, id) => (
                    <TableRow key={1}>
                      <StyledTableCell>
                        <Typography>
                          {state.addItemsArr.length + id + 1}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell sx={{ width: "30%" }}>
                        <Autocomplete
                          size="small"
                          options={state.productData}
                          fullWidth
                          value={item.modelName}
                          // inputValue={state.modelSearch}
                          onChange={(e, value) =>
                            handleNewOption(e, value, item.id)
                          }
                          onInputChange={(e, value) =>
                            handleNewInput(e, value, item.id)
                          }
                          getOptionLabel={(option) =>
                            option?.modelName ? option?.modelName : option
                          }
                          renderInput={(params) => (
                            <CustomField
                              {...params}
                              label="Select or Type brand name"
                              variant="outlined"
                              inputRef={inputRef}
                              // InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          type="number"
                          disabled
                          name="qty"
                          value={item.currentStock}
                          sx={{
                            background: "#FFF",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          type="number"
                          name="qty"
                          value={item.qty}
                          onChange={(e) => handleNewFeature(e, item.id)}
                          label="Add Quantity"
                          sx={{
                            background: "#FFF",
                          }}
                        />
                      </StyledTableCell>

                      <StyledTableCell>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          label="price"
                          type="number"
                          value={item.price}
                          name="price"
                          onChange={(e) => handleNewFeature(e, item.id)}
                          sx={{
                            background: "#FFF",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell sx={{ width: "15%" }}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          type="number"
                          disabled
                          name="qty"
                          value={item.subTotal}
                          sx={{
                            background: "#FFF",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <span
                          style={{ color: "red" }}
                          onClick={() => deleteRow(item.id)}>
                          <DeleteIcon />
                        </span>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                  <TableRow key={1}>
                    <StyledTableCell>
                      <Typography>
                        {state.addItemsArr.length + newItems.length + 1}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "30%" }}>
                      <Autocomplete
                        size="small"
                        options={state.productData}
                        fullWidth
                        value={state.modelName}
                        inputValue={state.selectedModel}
                        onChange={(e, value) => handleModelChange(e, value)}
                        onInputChange={(e, value) => handleIChange(e, value)}
                        getOptionLabel={(option) =>
                          option?.modelName ? option?.modelName : option
                        }
                        renderInput={(params) => (
                          <CustomField
                            {...params}
                            label="Select or Type brand name"
                            variant="outlined"
                            // InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        type="number"
                        disabled
                        name="qty"
                        sx={{
                          background: "#FFF",
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        type="number"
                        disabled
                        name="qty"
                        label="Add Quantity"
                        sx={{
                          background: "#FFF",
                        }}
                      />
                    </StyledTableCell>

                    <StyledTableCell>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        label="price"
                        type="number"
                        name="price"
                        disabledn
                        sx={{
                          background: "#FFF",
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        type="number"
                        disabled
                        name="qty"
                        sx={{
                          background: "#FFF",
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Stack
          direction={fullScreen ? "column" : "row"}
          justifyContent={"space-around"}
          alignItems={"center"}
          spacing={1}
          sx={{
            borderRadius: "8px",
            border: "0px 0px 1px 0px",
            background: "rgb(245, 255, 246)",
            width: "100%",
            padding: "10px",
            boxShadow: "2px 2px 2px 2px rgb(245, 255, 246)",
          }}
          item>
          <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            name="subTotal"
            label="Sub Total"
            value={state.subTotal}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            disabled
          />

          <TextField
            id="outlined-basic"
            variant="outlined"
            name="prevBalance"
            size="small"
            disabled
            label="Previous Balance"
            value={state.prevBalance}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            onChange={handleChange}
          />

          <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            name="total"
            disabled
            value={state.total}
            label="Total"
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            onChange={handleChange}
          />

          <TextField
            id="outlined-basic"
            variant="outlined"
            name="paid"
            type="number"
            size="small"
            label="Paid"
            value={state.paid}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
            onChange={handleChange}
          />

          <TextField
            id="outlined-basic"
            variant="outlined"
            name="balance"
            type="number"
            label="Balance"
            size="small"
            disabled
            value={state.balance}
            onChange={handleChange}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
          />

          <CustomBtn
            onClick={cancelSale}
            size="small"
            sx={{
              background: "red",
              color: "#FFF",
              width: "150px",
              "&:hover": {
                background: "red",
              },
            }}>
            Cancel Sale
          </CustomBtn>

          <CustomBtn
            size="small"
            onClick={makeBill}
            sx={{
              background: "#52b335",
              color: "#FFF",
              width: "150px",

              "&:hover": {
                background: "#61D140",
              },
            }}>
            {customData._id ? "update Sale" : "Sale"}
          </CustomBtn>
        </Stack>
      </Grid>
    </>
  );
}

export default AddSale;
