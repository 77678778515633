import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { Btn } from "../login";
import Form from "./Form";
import Logo from "../../assets/logo.png";

const Register = ({ regType }) => {
  const [state, setState] = useState({
    loginType: "admin",
  });

  const changeType = (input) => {
    setState((prev) => ({ ...prev, loginType: input }));
  };

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ background: "#F0F0F0", minHeight: "100vh", width: "100%" }}>
        <Typography
          sx={{ fontWeight: "bold", color: "#52b335", fontSize: "24px" }}>
          Register new account!
        </Typography>
        <Typography sx={{ color: "#B8B8B8", mt: 1 }}>
          Enter your credentials below
        </Typography>
        <Stack
          direction="row"
          sx={{
            marginTop: "20px",
            borderBottom: 1,
            borderColor: "#52b335",
            borderRadius: "5%",
          }}>
          <Btn
            onClick={() => changeType("employee")}
            sx={{
              background: state.loginType === "employee" ? "#52b335" : "#FFF",
              color: state.loginType === "employee" ? "#fff" : "#9F9E9E",
            }}>
            Employee
          </Btn>
          <Btn
            onClick={() => changeType("admin")}
            sx={{
              background: state.loginType === "admin" ? "#52b335" : "#FFF",
              color: state.loginType === "admin" ? "#fff" : "#9F9E9E",
            }}>
            Admin
          </Btn>
        </Stack>
        <Form
          loginType={state.loginType}
          regType={(type, data) => regType(type, data)}
        />
      </Stack>
    </>
  );
};

export default Register;
