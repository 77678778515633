import { Button, Stack, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Layout } from "../dashboard/dashboard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataTable from "./BankTable";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CustomDialogue from "../reuseableComp/Modal";
import { BackBtn, CustomField } from "../client";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { getBankById, getBanks, saveBank, updateBank } from "../../apiservices";
import { toast } from "react-toastify";
import { Lay } from "../client";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Back } from "../../assets/Svg";
import SimpleTable from "./SimpleTable";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ReactToPrint from "react-to-print";
import Search from "../searchBar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
const Btn = styled(Button)({
  height: "36px",
  padding: "4px",
  borderRadius: "4px",
  textTransform: "none",
  background: "#FFFFFF",
  color: "#52b335",
  marginRight: 10,
  "&:hover": {
    background: "#F6F6F6",
  },
  "&:active": {
    background: "#FFFFFF",
  },
});
export const Typo = styled(Typography)({
  fontWeight: 700,
  fontSize: "20px",
  color: "#52b335",
});
export const clients = [
  "Client Name",
  "Client Name",
  "Client Name",
  "Client Name",
  "Client Name",
];
export const schema = yup
  .object({
    name: yup.string().required(),
    address: yup.string().required(),
    num: yup.string().required(),
  })
  .required();
const BankComp = () => {
  const componentRef = React.useRef();
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const CustomTypo = styled(Typography)({
    fontSize: fullScreen ? "12px" : "16px",
  });
  const [state, setState] = useState({
    openModal: false,
    banksList: [],
    check: false,
    updatedData: [],
    bankData: {},
    viewBank: false,
    clientDataById: [],
    searchData: [],
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const getBanksList = async () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    let obj = {
      company_code: userData.company_code,
    };
    let res = await getBanks(obj);
    if (res) {
      setState((prev) => ({
        ...prev,
        banksList: res.bankDetails,
        searchData: res.bankDetails,
      }));
    }
  };
  useEffect(() => {
    getBanksList();
  }, []);

  const handleModal = (data = "") => {
    setState((prev) => ({
      ...prev,
      openModal: !prev.openModal,
      check: false,
      updatedData: data,
    }));
    if (data?._id) {
      setValue("name", data.bank_name);
      setValue("address", data.address);
      setValue("num", data.account_number);
    }
  };
  const submitForm = async (data) => {
    let userData = JSON.parse(localStorage.getItem("user"));
    if (state.check) {
      let obj = {
        _id: state.updatedData._id,
        _rev: state.updatedData._rev,
        bank_name: data.name,
        account_number: data.num,
        address: data.address,
        company_code: userData.company_code,
      };
      let res = await updateBank(obj);
      if (res) {
        toast.success("bank updated successfully");
        handleModal();
        getBanksList();
      }
    } else {
      let obj = {
        bank_name: data.name,
        account_number: data.num,
        address: data.address,
        company_code: userData.company_code,
      };
      let res = await saveBank(obj);
      if (res) {
        toast.success("bank saved successfully");
        handleModal();
        getBanksList();
      }
    }
  };
  const handleCheck = () => {
    setState((prev) => ({ ...prev, check: !prev.check }));
  };
  const getBankId = async (data) => {
    let userData = JSON.parse(localStorage.getItem("user"));
    let obj = {
      _id: data._id,
      company_code: userData.company_code,
      account_number: data.account_number,
      account_title: data.address,
    };
    let res = await getBankById(obj);
    if (res) {
      setState((prev) => ({ ...prev, clientDataById: res }));
    }
  };
  const viewBank = (data = "") => {
    if (data._id) {
      getBankId(data);
    }
    setState((prev) => ({
      ...prev,
      viewBank: !prev.viewBank,
      bankData: data,
    }));
  };
  const printToPDF = () => {
    // Select the content you want to print to PDF by its ID or class
    const contentToPrint = document.getElementById("content-to-print");

    // Use html2canvas to capture the content as an image
    html2canvas(contentToPrint).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Set dimensions for the PDF
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210; // A4 size
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add the captured image to the PDF
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

      // Download the PDF
      pdf.save("content.pdf");
    });
  };
  const ComponentToPrint = React.forwardRef((props, ref) => (
    <Stack ref={ref} id="content-to-print" sx={{ padding: 3 }}>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        direction={"row"}
        sx={{ mt: 2 }}>
        <PersonAddIcon sx={{ mx: 1 }} />
        <Typography sx={{ fontWeight: 600, fontSize: "24px" }}>
          {state.bankData.bank_name}
        </Typography>
      </Stack>
      <Stack sx={{ mb: 3 }}>
        <Lay
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ paddingLeft: 2, paddingRight: 2 }}>
          <Typography>Account Title:</Typography>
          <Typography>{state.bankData.address}</Typography>
        </Lay>
        <Lay
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ paddingLeft: 2, paddingRight: 2 }}>
          <Typography>Account Number:</Typography>
          <Typography>{state.bankData.account_number}</Typography>
        </Lay>
      </Stack>
      <SimpleTable data={state.clientDataById.credit} />
      <Lay
        direction={fullScreen ? "column" : "row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ paddingRight: 2, mt: 1 }}>
        <Stack direction={"row"}></Stack>
        <Stack direction={"row"}></Stack>
        <Stack direction={"row"} justifyContent="end" alignItems={"end"}>
          <CustomTypo>Total Credits:</CustomTypo>
          <CustomTypo>Rs {state.clientDataById.balance}</CustomTypo>
        </Stack>
        <Stack direction={"row"} justifyContent="end" alignItems={"end"}>
          <CustomTypo>Total Balance:</CustomTypo>
          <CustomTypo> {`Rs ${state.clientDataById.balance}`}</CustomTypo>
        </Stack>
      </Lay>
    </Stack>
  ));
  const updateData = (data) => {
    setState((prev) => ({ ...prev, banksList: data }));
  };
  return (
    <Stack sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <>
        {state.viewBank ? (
          <Stack>
            <Layout
              justifyContent="end"
              alignItems={"center"}
              direction={"row"}>
              <Btn onClick={viewBank}>
                <Back sx={{ mx: 1 }} />
                {!fullScreen && "Back"}
              </Btn>
              <ReactToPrint
                trigger={() => (
                  <Btn>
                    <PictureAsPdfIcon fontSize="small" sx={{ mx: 1 }} />
                    {!fullScreen && "PDF"}
                  </Btn>
                )}
                content={() => componentRef.current}
              />

              <ReactToPrint
                trigger={() => (
                  <Btn>
                    <PrintIcon fontSize="small" sx={{ mx: 1 }} />{" "}
                    {!fullScreen && "Print"}
                  </Btn>
                )}
                content={() => componentRef.current}
              />
            </Layout>
            <ComponentToPrint ref={componentRef} />
          </Stack>
        ) : (
          <>
            <Layout
              justifyContent="space-between"
              alignItems={"center"}
              direction={"row"}>
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "24px",
                  fontWeight: 700,
                }}></Typography>
              <Typography
                sx={{ color: "#FFF", fontSize: "24px", fontWeight: 700 }}>
                Banks
              </Typography>
              <Btn onClick={handleModal}>
                <AddCircleIcon fontSize="small" sx={{ mx: 1 }} /> Add new bank
              </Btn>
            </Layout>
            <Stack sx={{ padding: "20px" }}>
              <Stack
                justifyContent={"start"}
                alignItems={"start"}
                sx={{
                  width: "50%",
                }}>
                <Search
                  data={state.searchData}
                  updateData={(data) => updateData(data)}
                  place1={" Search by Bank name"}
                  place2={""}
                />
              </Stack>
              <DataTable
                editBankDetails={(data) => handleModal(data)}
                bankList={state.banksList}
                handleCheck={handleCheck}
                getData={getBanksList}
                viewBank={(data) => viewBank(data)}
              />
            </Stack>
          </>
        )}
      </>
      <CustomDialogue
        open={state.openModal}
        handleClose={handleModal}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <AccountBalanceWalletIcon
              sx={{ color: "#52b335" }}
              fontSize="large"
            />
            <Typo>Add new bank</Typo>
          </Stack>
        }
        content={
          <Stack sx={{ padding: 1 }}>
            <CustomField
              fullWidth
              id="outlined-select-currency"
              label="Enter Bank Name"
              {...register("name")}
              sx={{ mb: 2 }}
            />
            <Typography sx={{ color: "red" }}>
              {errors.name?.message}
            </Typography>

            <CustomField
              fullWidth
              id="outlined-select-currency"
              label="Enter Account Title"
              {...register("address")}
              sx={{ mb: 2 }}
            />
            <Typography sx={{ color: "red" }}>
              {errors.address?.message}
            </Typography>

            <CustomField
              fullWidth
              id="outlined-select-currency"
              label="Enter Account Number"
              {...register("num")}
              sx={{ mb: 2 }}
            />
            <Typography sx={{ color: "red" }}>{errors.num?.message}</Typography>

            <Stack spacing={3} direction={"row"} sx={{ mt: 3 }}>
              <Button
                size="small"
                onClick={handleSubmit(submitForm)}
                sx={{
                  width: "250px",
                  textTransform: "none",
                  background: "#52b335",
                  color: "#FFF",
                  "&:hover": {
                    background: "#61D140",
                  },
                }}>
                {state.check ? "update bank details" : " Save Bank Details"}
              </Button>
              <Button
                onClick={handleModal}
                size="small"
                sx={{
                  background: "#FFF",
                  width: "250px",
                  textTransform: "none",

                  color: "#9F9E9E",
                  "&:hover": {
                    background: "#EFEFEF",
                  },
                }}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        }
      />
    </Stack>
  );
};

export default BankComp;
