import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";

const FeaturesList = styled("div")({
  margin: "20px 0px",
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
});

function EmpDetails({ user, onSuccess, onClose }) {
  console.log(user);
  const [state, setState] = useState({
    Items: false,
    Ledgers: false,
    Dashboard: false,
    saleItems: false,
    daybook: false,
    Vendors: false,
    fastCash: false,
    Voucher: false,
    Summary: false,
    Quotation: false,
    purchaseOrder: false,
    saleReturn: false,
    rollItems: false,
    Bills: false,
    employeeSalary: false,
    Invoice: false,
    wareHouse: false,
    scanningPos: false,
    Reports: false,
  });

  useLayoutEffect(() => {
    getAccessArray();
  }, []);

  const getAccessArray = () => {
    let obj = {};
    if (user.access && user.access.length > 0) {
      user?.access?.forEach((item) => {
        if (item == "Sale Items") {
          obj.saleItems = true;
        } else if (item == "Purchase Order") {
          obj.purchaseOrder = true;
        } else if (item == "Employee Salary") {
          obj.employeeSalary = true;
        } else if (item == "Sale Return") {
          obj.saleReturn = true;
        } else if (item == "Roll Items") {
          obj.rollItems = true;
        } else if (item == "Day Book") {
          obj.daybook = true;
        } else if (item == "Scanning POS") {
          obj.scanningPos = true;
        } else if (item == "Fast Cash") {
          obj.fastCash = true;
        } else {
          obj[item] = true;
        }
      });
    }
    setState((prev) => ({ ...prev, ...obj }));
  };

  const updateAccess = async () => {
    onClose();
    let arr = [];
    for (const key in state) {
      if (state[key] == true) {
        if (key == "saleItems") {
          arr.push("Sale Items");
        } else if (key == "purchaseOrder") {
          arr.push("Purchase Order");
        } else if (key == "employeeSalary") {
          arr.push("Employee Salary");
        } else if (key == "saleReturn") {
          arr.push("Sale Return");
        } else if (key == "rollItems") {
          arr.push("Roll Items");
        } else if (key == "daybook") {
          arr.push("Day Book");
        } else if (key == "scanningPos") {
          arr.push("Scanning POS");
        } else if (key == "fastCash") {
          arr.push("Fast Cash");
        } else {
          arr.push(key);
        }
      }
    }

    onSuccess();
  };
  const handleChange = (e) => {
    const name = e.target.name;
    setState((prev) => ({ ...prev, [name]: !prev[name] }));
  };
  return (
    <Box sx={{ margin: "0px 20px" }}>
      <Stack sx={{ mb: 2 }} spacing={2}>
        <Stack direction="row" justifyContent="center" gap={2}>
          <Typography variant="h5">Name</Typography>
          <Typography variant="h5" sx={{ color: "#4EAF51" }}>
            {user.email}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" gap={2}>
          <Typography variant="h5">ID</Typography>
          <Typography variant="h5" sx={{ color: "#4EAF51" }}>
            {user._id}
          </Typography>
        </Stack>
      </Stack>

      {/* <FeaturesList>
        <FormControlLabel
          control={
            <Checkbox
              name="Dashboard"
              checked={state.Dashboard}
              onChange={handleChange}
            />
          }
          label="Dashboard"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="saleItems"
              checked={state.saleItems}
              onChange={handleChange}
            />
          }
          label="Sale Items"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="Items"
              checked={state.Items}
              onChange={handleChange}
            />
          }
          label="Items"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="daybook"
              checked={state.daybook}
              onChange={handleChange}
            />
          }
          label="Daybook"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="Ledgers"
              checked={state.Ledgers}
              onChange={handleChange}
            />
          }
          label="Ledgers"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="purchaseOrder"
              checked={state.purchaseOrder}
              onChange={handleChange}
            />
          }
          label="Purchase Order"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="Reports"
              checked={state.Reports}
              onChange={handleChange}
            />
          }
          label="Reports"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="scanningPos"
              checked={state.scanningPos}
              onChange={handleChange}
            />
          }
          label="Scanning POS"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="Bills"
              checked={state.Bills}
              onChange={handleChange}
            />
          }
          label="Bills"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="saleReturn"
              checked={state.saleReturn}
              onChange={handleChange}
            />
          }
          label="Sale Return"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="Quotation"
              checked={state.Quotation}
              onChange={handleChange}
            />
          }
          label="Quotation"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="Voucher"
              checked={state.Voucher}
              onChange={handleChange}
            />
          }
          label="Voucher"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="employeeSalary"
              checked={state.employeeSalary}
              onChange={handleChange}
            />
          }
          label="Salary"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="Invoice"
              checked={state.Invoice}
              onChange={handleChange}
            />
          }
          label="Inovice"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="wareHouse"
              checked={state.wareHouse}
              onChange={handleChange}
            />
          }
          label="Warehouse"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="Summary"
              checked={state.Summary}
              onChange={handleChange}
            />
          }
          label="Summary"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="rollItems"
              checked={state.rollItems}
              onChange={handleChange}
            />
          }
          label="Roll Items"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="Vendors"
              checked={state.Vendors}
              onChange={handleChange}
            />
          }
          label="Vendors"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="fastCash"
              checked={state.fastCash}
              onChange={handleChange}
            />
          }
          label="Fast Cash"
        />
      </FeaturesList> */}
      <Stack direction="row" justifyContent="space-evenly" gap={3}>
        <Button
          fullWidth
          // variant="contained"
          sx={{
            color: "white",
            background: "#52b335",
            "&:hover": {
              background: "#52b335",
            },
          }}
          onClick={updateAccess}>
          Save
        </Button>
        <Button fullWidth variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </Stack>
    </Box>
  );
}

export default EmpDetails;
