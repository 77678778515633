import { Stack, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getClient,
  getDailyPayment,
  getDailyProfitLoss,
  getDailySales,
  getTotalCurrentStock,
} from "../../apiservices";
import Sale from "../../assets/sale.png";
import Payment from "../../assets/payment.png";
import Report from "../../assets/report.png";
import Profit from "../../assets/profit.png";
import Stock from "../../assets/stock.png";
import Transaction from "../../assets/transaction.png";
import { useDispatch } from "react-redux";
import { handleSelectedCom } from "../../sidebar/toggleSlice";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
export const Layout = styled(Stack)({
  width: "100%",
  height: "64px",
  marginTop: "10px",
  borderRadius: "8px",
  background: "#52b335",
});
export const RoundBox = styled(Stack)({
  padding: 4,
  backgroundColor: "#52b335",
  height: "175px",
  minWidth: "250px",
  borderRadius: "25px",
  ":hover": {
    transform: "scale3d(1.05, 1.05, 1)",
    boxShadow: "0 10px 10px #fff",
  },
});

function Dashboard() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [state, setState] = useState({
    dailyPayment: 0,
    dailySale: 0,
    dailyLoss: 0,
    dailyProfit: 0,
    dailyStock: 0,
    clientData: [],
  });

  const dispatch = useDispatch();
  const handleSelection = (arg) => {
    dispatch(handleSelectedCom(arg));
  };
  const getPayment = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    let obj = {
      company_code: userData.company_code,
    };
    let res = await getDailyPayment(obj);
    setState((prev) => ({ ...prev, dailyPayment: res.dailyPayment }));
  };
  const getCurrentStock = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    let obj = {
      company_code: userData.company_code,
    };
    let res = await getTotalCurrentStock(obj);
    setState((prev) => ({ ...prev, dailyStock: res.totalStock }));
  };
  const getSales = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    let obj = {
      company_code: userData.company_code,
    };
    let res = await getDailySales(obj);
    setState((prev) => ({ ...prev, dailySale: res.dailySales }));
  };

  const getClientsData = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    let obj = {
      company_code: userData.company_code,
    };
    let res = await getClient(obj);
    if (res) {
      setState((prev) => ({ ...prev, clientData: res }));
    }
  };

  useEffect(() => {
    getPayment();
    getSales();
    getCurrentStock();
    getClientsData();
  }, []);

  return (
    <Stack alignItems={"center"} sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <Layout justifyContent="center" alignItems={"center"}>
        <Typography sx={{ color: "#FFF", fontSize: "24px", fontWeight: 700 }}>
          Dashboard
        </Typography>
      </Layout>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ mt: 10 }}
        spacing={5}>
        <Stack container direction={fullScreen ? "column" : "row"} spacing={10}>
          <RoundBox onClick={() => handleSelection("transaction")}>
            <Stack alignItems={"start"} sx={{ padding: 2 }}>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                Transaction
              </Typography>
            </Stack>
            <Stack alignItems={"end"}>
              <img
                src={Transaction}
                alt=""
                width={"40px"}
                style={{ marginRight: 5 }}
              />
            </Stack>
          </RoundBox>
          <RoundBox onClick={() => handleSelection("sale")}>
            <Stack alignItems={"start"} sx={{ padding: 2 }}>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                Today Sale
              </Typography>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                {state.dailySale}
              </Typography>
            </Stack>
            <Stack alignItems={"end"}>
              <img
                src={Sale}
                alt=""
                width={"40px"}
                style={{ marginRight: 5 }}
              />
            </Stack>
          </RoundBox>
          <RoundBox onClick={() => handleSelection("payment")}>
            <Stack alignItems={"start"} sx={{ padding: 2 }}>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                Today Payment
              </Typography>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                {state.dailyPayment}
              </Typography>
            </Stack>
            <Stack alignItems={"end"}>
              <img
                src={Payment}
                alt=""
                width={"40px"}
                style={{ marginRight: 5 }}
              />
            </Stack>
          </RoundBox>
        </Stack>
        <Stack direction={fullScreen ? "column" : "row"} spacing={10}>
          <RoundBox onClick={() => handleSelection("stockSheet")}>
            <Stack alignItems={"start"} sx={{ padding: 2 }}>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                Current Stock
              </Typography>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                {state.dailyStock}
              </Typography>
            </Stack>
            <Stack alignItems={"end"}>
              <img
                src={Stock}
                alt=""
                width={"40px"}
                style={{ marginRight: 5 }}
              />
            </Stack>
          </RoundBox>
          <RoundBox onClick={() => handleSelection("clientSheet")}>
            <Stack alignItems={"start"} sx={{ padding: 2 }}>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                Client Report
              </Typography>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                {state.clientData.totalCurrentBalance}
              </Typography>
            </Stack>
            <Stack alignItems={"end"}>
              <img
                src={Report}
                alt=""
                width={"40px"}
                style={{ marginRight: 5 }}
              />
            </Stack>
          </RoundBox>
          <RoundBox onClick={() => handleSelection("profit")}>
            <Stack alignItems={"start"} sx={{ padding: 2 }}>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                Profit / Loss
              </Typography>
            </Stack>
            <Stack alignItems={"end"}>
              <img
                src={Profit}
                alt=""
                width={"40px"}
                style={{ marginRight: 5 }}
              />
            </Stack>
          </RoundBox>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Dashboard;
