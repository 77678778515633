import axios from "axios";

export const loginUser = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/users`, {
    request: {
      method: "getUser",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const loginAdmin = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/users`, {
    request: {
      method: "loginAdminZRT",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const regAdmin = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/users`, {
    request: {
      method: "signupAdminZRT",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const regUser = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/users`, {
    request: {
      method: "saveUser",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const saveBank = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/bank`, {
    request: {
      method: "saveBankNew",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getBanks = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/bank`, {
    request: {
      method: "getBank",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const updateBank = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/bank`, {
    request: {
      method: "updateBank",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const deleteBank = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/bank`, {
    request: {
      method: "deleteBank",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getProductsSearch = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/products`,
    {
      request: {
        method: "getModelsSearch",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const saveBills = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/bills`, {
    request: {
      method: "saveBillsZkTraders",
      data: { bill: userData },
    },
  });
  let json = res.data.response.data;
  return json;
};
export const saveSale = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/sales`, {
    request: {
      method: "saveSaleZkTraders",
      data: { sales: userData },
    },
  });
  let json = res.data.response.data;
  return json;
};
export const updateItem = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/products`,
    {
      request: {
        method: "updateItem",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const getBills = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/bills`, {
    request: {
      method: "getBillsZkTraders",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getOutBills = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/bills`, {
    request: {
      method: "getOutstandingBills",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const saveProvider = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/providers`,
    {
      request: {
        method: "saveProvider",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const getProvider = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/providers`,
    {
      request: {
        method: "getProvider",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const updateProvider = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/providers`,
    {
      request: {
        method: "updateProvider",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const deleteProvider = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/providers`,
    {
      request: {
        method: "deleteProvider",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const saveProduct = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/products`,
    {
      request: {
        method: "saveProduct",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const getProduct = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/products`,
    {
      request: {
        method: "getProduct",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const updateProduct = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/products`,
    {
      request: {
        method: "updateProduct",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const deleteProduct = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/products`,
    {
      request: {
        method: "deleteProduct",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const saveStockNew = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/stock`, {
    request: {
      method: "saveStockNew",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getStockNew = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/stock`, {
    request: {
      method: "getStockNew",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const updateStockNew = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/stock`, {
    request: {
      method: "updateStockNew",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const deleteStockNew = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/stock`, {
    request: {
      method: "deleteStockNew",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getClient = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/client`,
    {
      request: {
        method: "getClient",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const saveClient = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/client`,
    {
      request: {
        method: "saveClient",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const updateClient = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/client`,
    {
      request: {
        method: "updateClient",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const deleteClient = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/client`,
    {
      request: {
        method: "deleteClient",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const savePaymentNew = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/payments`,
    {
      request: {
        method: "savePaymentNew",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const updateSpecificPayment = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/payments`,
    {
      request: {
        method: "updateSpecificPayment",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const getPayment = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/payments`,
    {
      request: {
        method: "getPayment",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const deletePayments = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/payments`,
    {
      request: {
        method: "deleteSpecificPayment",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const saveCity = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/client`,
    {
      request: {
        method: "saveCity",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const getCity = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/client`,
    {
      request: {
        method: "getCity",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const saveCountry = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/client`,
    {
      request: {
        method: "saveCountry",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const getCountry = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/client`,
    {
      request: {
        method: "getCountry",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const updateCountry = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/client`,
    {
      request: {
        method: "updateCountry",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const deleteCountry = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/client`,
    {
      request: {
        method: "deleteCountry",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const getDailyPayment = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/payments`,
    {
      request: {
        method: "getDailyPayment",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};

export const getDailyProfitLoss = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/sales`, {
    request: {
      method: "getSalesProfitLossZRT",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getDailySales = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/sales`, {
    request: {
      method: "getDailySalesZk",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getBankById = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/bank`, {
    request: {
      method: "getBankById",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getClientById = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/client`,
    {
      request: {
        method: "getClientsByIdNew",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const saveCategories = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/products`,
    {
      request: {
        method: "saveCategories",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const getCategories = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/products`,
    {
      request: {
        method: "getCategory",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const updateCategory = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/products`,
    {
      request: {
        method: "updateCategory",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const deleteCategory = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/products`,
    {
      request: {
        method: "deleteCategory",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const getTotalCurrentStock = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/stock`, {
    request: {
      method: "getTotalCurrentStock",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const deleteBillZkTraders = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/bills`, {
    request: {
      method: "deleteBillZkTraders",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getEmployeesZRTraders = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/users`, {
    request: {
      method: "getEmployeesZRTraders",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getPaymentByRange = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/payments`,
    {
      request: {
        method: "getPaymentByRange",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const getRangeSalesByCompanyZRT = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/sales`, {
    request: {
      method: "getRangeSalesByCompanyZRT",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const updateUserProfile = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/users`, {
    request: {
      method: "updateUserProfile",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const updateBillZkTraders = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/bills`, {
    request: {
      method: "updateBillZkTraders",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getProductDetailsZRT = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/products`,
    {
      request: {
        method: "getProductDetailsZRT",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const updateClientPaymentSale = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/client`,
    {
      request: {
        method: "updateClientPaymentSale",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const updateLedgerSalePayments = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/client`,
    {
      request: {
        method: "updateLedgerSalePayments",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const updateClientBalance = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/client`,
    {
      request: {
        method: "updateClientBalance",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const deleteBillByClient = async (userData) => {
  let res = await axios.post(`https://shrouded-tor-53503.herokuapp.com/bills`, {
    request: {
      method: "deleteBillByClient",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const deleteSpecificPaymentFromClient = async (userData) => {
  let res = await axios.post(
    `https://shrouded-tor-53503.herokuapp.com/payments`,
    {
      request: {
        method: "deleteSpecificPaymentFromClient",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
