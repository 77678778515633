import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@mui/material";

const Dialoge = styled(Dialog)({
  minWidth: "300px",
  textAlign: "center",
});
const Actions = styled(DialogActions)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const BtnCancel = styled(Button)({
  backgroundColor: "rgb(138, 138, 138)",
  border: "0",
  margin: "20px",
  width: "230px",
  color: "white",
  borderRadius: "5px",
  padding: "5px 10px",
  fontSize: "large",
  cursor: "pointer",
  textShadow: "1px 1px 2px rgb(161, 161, 161)",
  boxShadow: "0px 1px 2px rgb(161, 161, 161)",
  "&:hover": {
    backgroundColor: "rgb(138, 138, 138)",
  },
});
const BtnAgree = styled(Button)({
  backgroundColor: "#4daf51",
  border: "0",
  margin: "20px",
  width: "230px",
  color: "white",
  borderRadius: "5px",
  padding: "5px 10px",
  fontSize: "large",
  cursor: "pointer",
  textShadow: "1px 1px 2px rgb(161, 161, 161)",
  boxShadow: "0px 1px 2px rgb(161, 161, 161)",
  "&:hover": {
    backgroundColor: "#4EAF50",
  },
});
function DialogComponent({
  title,
  open,
  onClose,
  onClickCancel,
  onClickYes,
  dialogTitle,
  dialogContent,
  buttonDisAgreeTitle,
  buttonAgreeTitle,
  width = true,
}) {
  return (
    <div>
      <Dialoge
        title={title}
        fullWidth={width}
        open={open}
        onClose={onClose}
        disableEnforceFocus>
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>{dialogContent}</DialogContent>
        <Actions>
          {buttonDisAgreeTitle ? (
            <BtnCancel onClick={onClickCancel}>{buttonDisAgreeTitle}</BtnCancel>
          ) : null}
          {buttonAgreeTitle ? (
            <BtnAgree onClick={onClickYes}>{buttonAgreeTitle}</BtnAgree>
          ) : null}
        </Actions>
      </Dialoge>
    </div>
  );
}

export default DialogComponent;
