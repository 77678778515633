import styled from "@emotion/styled";
import {
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CancelIcon from "@mui/icons-material/Cancel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Btn } from "../login";
import { regAdmin, regUser } from "../../apiservices";
import { toast } from "react-toastify";
export const CustomFormControl = styled(FormControl)({
  m: 1,
  background: "#FFF",
  padding: "2px",
  borderRadius: "4px",
});
export const schema = yup
  .object({
    name: yup.string().required(),
    address: yup.string().required(),
    code: yup.string().optional(),
    cName: yup.string().optional(),
    email: yup.string().required(),
    phone: yup.string().required(),
    confirmPass: yup.string().required(),
    password: yup.string().required(),
  })
  .required();
function Form({ loginType, regType }) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const handleCancel = (name) => {
    setValue(`${name}`, "");
  };
  const userReg = async (data) => {
    let username = data.name;
    let userid = Math.floor(Math.random() * 9000 + 1000);
    let Id = userid.toString();
    let userData = {
      username: username,
      _id: Id,
      company_code: data.companyCode.toString(),
    };
    let res = await regUser(userData);
    console.log(res);
  };

  const adminReg = async (data) => {
    let credentials = {};
    if (data.code) {
      credentials = {
        user: {
          name: data.name,
          email: data.email,
          company_name: data.cName,
          address: data.address,
          company_code: data.code,
          phone: data.phone,
          password: data.password,
        },
      };
    } else {
      credentials = {
        user: {
          name: data.name,
          email: data.email,
          company_name: data.cName,
          address: data.address,
          phone: data.phone,
          password: data.password,
        },
      };
    }
    let res = await regAdmin(credentials);
    if (res?._id) {
      regType("regType", "login");
      toast.success("Registered Successfully");
    }
  };
  const submitForm = () => {
    loginType === "admin" ? handleSubmit(adminReg)() : handleSubmit(userReg)();
  };
  return (
    <Stack justifyContent="center" sx={{ width: "75%" }}>
      {loginType === "admin" ? (
        <>
          <Grid container sx={{ mt: 3 }} spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomFormControl>
                <InputLabel htmlFor="standard-adornment-password">
                  Admin Name
                </InputLabel>
                <OutlinedInput
                  id="standard-adornment-password"
                  type="text"
                  label=" Admin Name"
                  {...register("name")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleCancel("name")}>
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </CustomFormControl>
              <Typography sx={{ color: "red" }}>
                {errors.name?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomFormControl>
                <InputLabel htmlFor="standard-adornment-password">
                  {" "}
                  City
                </InputLabel>
                <OutlinedInput
                  id="standard-adornment-password"
                  type="text"
                  {...register("address")}
                  label="City"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleCancel("address")}>
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </CustomFormControl>
              <Typography sx={{ color: "red" }}>
                {errors.address?.message}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 1 }} spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomFormControl>
                <InputLabel htmlFor="standard-adornment-password">
                  {" "}
                  Company Code
                </InputLabel>
                <OutlinedInput
                  id="standard-adornment-password"
                  type="number"
                  label="Company Code"
                  {...register("code")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleCancel("code")}>
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </CustomFormControl>
              <Typography sx={{ color: "red" }}>
                {errors.code?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomFormControl>
                <InputLabel htmlFor="standard-adornment-password">
                  Company Name
                </InputLabel>
                <OutlinedInput
                  id="standard-adornment-password"
                  label="Company Name"
                  type="text"
                  {...register("cName")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleCancel("cName")}>
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </CustomFormControl>
              <Typography sx={{ color: "red" }}>
                {errors.cName?.message}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 1 }} spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomFormControl>
                <InputLabel htmlFor="standard-adornment-password">
                  Phone Number
                </InputLabel>
                <OutlinedInput
                  id="standard-adornment-password"
                  type="number"
                  label="Phone Number"
                  {...register("phone")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleCancel("phone")}>
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </CustomFormControl>
              <Typography sx={{ color: "red" }}>
                {errors.phone?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomFormControl>
                <InputLabel htmlFor="standard-adornment-password">
                  {" "}
                  Email
                </InputLabel>
                <OutlinedInput
                  id="standard-adornment-password"
                  type="text"
                  label="email"
                  {...register("email")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleCancel("email")}>
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </CustomFormControl>
              <Typography sx={{ color: "red" }}>
                {errors.password?.message}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 1 }} spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomFormControl>
                <InputLabel htmlFor="standard-adornment-password">
                  {" "}
                  Password
                </InputLabel>
                <OutlinedInput
                  label="password"
                  id="standard-adornment-password"
                  type={showPassword ? "text" : "password"}
                  {...register("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </CustomFormControl>
              <Typography sx={{ color: "red" }}>
                {errors.password?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomFormControl>
                <InputLabel htmlFor="standard-adornment-password">
                  {" "}
                  Re-enter Password
                </InputLabel>
                <OutlinedInput
                  id="standard-adornment-password"
                  label="Re-enter Password"
                  type={showPassword ? "text" : "password"}
                  {...register("confirmPass")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </CustomFormControl>
              <Typography sx={{ color: "red" }}>
                {errors.password?.message}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <CustomFormControl sx={{ mt: 4, mb: 2 }}>
            <InputLabel htmlFor="standard-adornment-password">Name</InputLabel>
            <OutlinedInput
              id="standard-adornment-password"
              type="text"
              label="Name"
              {...register("name")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleCancel("name")}>
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </CustomFormControl>
          <Typography sx={{ color: "red" }}>{errors.name?.message}</Typography>

          <CustomFormControl>
            <InputLabel htmlFor="standard-adornment-password">
              {" "}
              Company Code
            </InputLabel>
            <OutlinedInput
              id="standard-adornment-password"
              type="text"
              label="Company Code"
              {...register("code")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleCancel("code")}>
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </CustomFormControl>
          <Typography sx={{ color: "red" }}>{errors.code?.message}</Typography>
        </>
      )}
      <Stack alignItems="center" justifyContent="center">
        <Btn
          onClick={submitForm}
          sx={{
            background: "#52b335",
            color: "#fff",
            width: "40%",
            mt: 4,
            "&:hover": { background: "#61D140" },
            "&:active": { background: "#52b335" },
          }}>
          {" "}
          Register
        </Btn>
        <Btn
          onClick={() => regType("regType", "login")}
          sx={{
            background: "#fff",
            color: "#9F9E9E",
            width: "40%",
            mt: 2,
            mb: 2,
          }}>
          {" "}
          Cancel
        </Btn>
      </Stack>
    </Stack>
  );
}

export default Form;
