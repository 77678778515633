import "./App.css";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Login from "./Auth/login";
import { Box } from "@mui/material";
import Dashboard from "./components/dashboard";
import { useEffect } from "react";
function App() {
  let loggedIn = localStorage.getItem("loggedIn");
  const navigate = useNavigate();
  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    } else {
      navigate("/");
    }
  }, []);
  const AdminRoutes = () => {
    return (
      <div>
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    );
  };
  return (
    <Box>
      {!loggedIn && (
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="*" element={<Login />} />
        </Routes>
      )}
      {loggedIn && (
        <Routes>
          <Route path="/*" element={<AdminRoutes />} />
        </Routes>
      )}
    </Box>
  );
}

export default App;
