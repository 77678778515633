import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, styled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteStockNew } from "../../apiservices";
import { toast } from "react-toastify";
const Btn = styled(Button)({
  textTransform: "none",
  background: "#EEF4FF",
  color: "#52b335",
  "&:hover": {
    color: "#FFF",
    background: "#52b335",
  },
});

export default function DataTable({
  editStock,
  data,
  handleCheck,
  getData,
  openDetail,
  providersData,
  view,
}) {
  const userData = JSON.parse(localStorage.getItem("user"));
  if (data) {
    data.forEach((element) => {
      element["id"] = element._id;
      element["buyer"] = userData.name;
      let filtered = providersData.filter(
        (item) => item.providersName === element.providersName
      );
      if (filtered) {
        element["companyName"] = filtered[0]?.companyName;
      }
    });
  }
  const columns = [
    { field: "buyer", headerName: "Buyer", width: 200 },

    { field: "providersName", headerName: "Provider Name", width: 200 },

    { field: "companyName", headerName: "Company Name", width: 150 },

    {
      field: "totalPrice",
      headerName: "Total Amount",
      width: 150,
    },
    {
      field: "actions",
      headerName: "View Stock",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Btn
            variant="contained"
            color="primary"
            size="small"
            onClick={() => view(params.row)}>
            view stock
          </Btn>
          {/* You can add more buttons or customize based on your requirements */}
        </strong>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Btn
            variant="contained"
            color="primary"
            size="small"
            onClick={() => edit(params.row)}>
            Edit
          </Btn>

          {/* You can add more buttons or customize based on your requirements */}
        </strong>
      ),
    },

    {
      field: "remove",
      headerName: "Remove",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <strong style={{ color: "red" }}>
          <DeleteIcon onClick={() => deleteRow(params.row)} />
          {/* You can add more buttons or customize based on your requirements */}
        </strong>
      ),
    },
  ];

  const rows = data;
  const edit = (params) => {
    editStock(params);
    handleCheck();
  };
  const deleteRow = async (params) => {
    let obj = {
      _id: params._id,
    };
    let res = await deleteStockNew(obj);
    if (res) {
      toast.error("stock deleted successfully");
      getData();
    }
  };
  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        pageSizeOptions={[20, 50]}
        checkboxSelection
      />
    </div>
  );
}
