import React, { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { updateUserProfile } from "../../apiservices";
import { CustomField } from "../client";

function UpdatePassword({ id, onClose, onSuccess }) {
  const [state, setState] = useState({
    old_password: "",
    new_password: "",
    confirmPassword: "",
    isAbled: false,
    cName: id ? id?.name : "",
    address: id ? id?.address : "",
  });

  const handleInput = (e) => {
    const { value, name } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "old_password") {
      if (id.password === value) {
        setState((prev) => ({ ...prev, isAbled: true }));
      }
    }
  };
  const submit = async () => {
    if (state.isAbled) {
      if (state.new_password === state.confirmPassword) {
        let data = {
          _id: id._id,
          old_password: state.old_password,
          new_password: state.new_password,
          new_name: state.cName,
          new_address: state.address,
        };
        let res = await updateUserProfile(data);
        if (res) {
          localStorage.setItem("user", JSON.stringify(res?.user));
          onClose();
        }
      } else {
        toast.error("password donot match");
      }
    } else {
      if (!state.old_password) {
        let data = {
          _id: id._id,
          old_password: state.old_password,
          new_password: state.new_password,
          new_name: state.cName,
          new_address: state.address,
        };
        let res = await updateUserProfile(data);
        if (res) {
          localStorage.setItem("user", JSON.stringify(res?.user));
          onClose();
        }
      } else toast.error("Old password is not valid");
    }
  };
  return (
    <Stack gap={2}>
      <Typography variant="h6" sx={{ color: "#4EAF51" }}>
        Change Your Password
      </Typography>
      <CustomField
        autoFocus
        size="small"
        name="cName"
        label="Name"
        value={state.cName}
        fullWidth
        onChange={handleInput}
      />
      <CustomField
        autoFocus
        size="small"
        name="address"
        label="Address"
        value={state.address}
        fullWidth
        onChange={handleInput}
      />
      <CustomField
        autoFocus
        type="password"
        size="small"
        name="old_password"
        label="Old Password"
        value={state.old_password}
        fullWidth
        onChange={handleInput}
      />

      <CustomField
        label="New Password"
        size="small"
        name="new_password"
        disabled={!state.isAbled}
        value={state.new_password}
        type="password"
        fullWidth
        onChange={handleInput}
      />

      <CustomField
        size="small"
        label="Confirm Password"
        name="confirmPassword"
        disabled={!state.isAbled}
        value={state.confirmPassword}
        type="password"
        fullWidth
        onChange={handleInput}
      />

      <Stack direction="row" justifyContent="space-evenly" spacing={4}>
        <Button
          fullWidth
          sx={{
            border: "1px solid #52b335",
            color: "#52b335",
          }}
          onClick={onClose}>
          Cancel
        </Button>
        <Button
          fullWidth
          onClick={submit}
          sx={{
            background: "#52b335",
            "&:hover": {
              background: "#52b335",
            },
            color: "#FFF",
          }}>
          Save
        </Button>
      </Stack>
    </Stack>
  );
}

export default UpdatePassword;
