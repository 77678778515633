import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, styled } from "@mui/material";
import { deleteBank } from "../../apiservices";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";

const Btn = styled(Button)({
  textTransform: "none",
  background: "#EEF4FF",
  color: "#52b335",
  "&:hover": {
    color: "#FFF",
    background: "#52b335",
  },
});

export default function DataTable({
  editBankDetails,
  bankList,
  handleCheck,
  getData,
  viewBank,
}) {
  if (bankList) {
    bankList.forEach((element) => {
      element["id"] = element._id;
    });
  }
  const columns = [
    { field: "bank_name", headerName: "Bank Name", width: 200 },
    { field: "address", headerName: "Account Title", width: 300 },
    { field: "account_number", headerName: "Account No#", width: 200 },

    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Btn
            variant="contained"
            color="primary"
            size="small"
            onClick={() => edit(params.row)}>
            Edit
          </Btn>

          {/* You can add more buttons or customize based on your requirements */}
        </strong>
      ),
    },
    {
      field: "view",
      headerName: "View",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Btn
            variant="contained"
            color="primary"
            size="small"
            onClick={() => viewBank(params.row)}>
            View Bank
          </Btn>
          {/* You can add more buttons or customize based on your requirements */}
        </strong>
      ),
    },
    {
      field: "remove",
      headerName: "Remove",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <strong style={{ color: "red" }}>
          <DeleteIcon onClick={() => deleteRow(params.row)} />
          {/* You can add more buttons or customize based on your requirements */}
        </strong>
      ),
    },
  ];
  const rows = bankList;
  const edit = (params) => {
    editBankDetails(params);
    handleCheck();
  };
  const deleteRow = async (params) => {
    let obj = {
      _id: params._id,
    };
    let res = await deleteBank(obj);
    if (res) {
      toast.error("bank deleted successfully");
      getData();
    }
  };
  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        pageSizeOptions={[20, 50]}
        checkboxSelection
      />
    </div>
  );
}
