import React from "react";
import Sidebar from "../../sidebar";
import Dashboard from "./dashboard";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import Sale from "../sale";
import Payment from "../payment";
import Client from "../client";
import Stock from "../stock";
import Product from "../product";
import Provider from "../Provider";
import BankComp from "../bank";
import CountryComp from "../country";
import CategoryComp from "../categories";
import ClientSheet from "../client/clientSheet";
import StockSheet from "../stock/stockSheet";
import TransactionComp from "../transaction";
import CompanyProfile from "../company profile/CompanyProfile";
import ProfitComp from "../profit";
function Dashboad() {
  const { selectedCom } = useSelector((state) => state.sidebar);
  return (
    <Box sx={{ width: "100%", display: "flex", alignItems: "stretch" }}>
      <Box sx={{ flex: "0 0 auto" }}>
        <Sidebar />
      </Box>
      <Box
        sx={{
          flex: "1 1 auto",
          overflowX: "auto",
          height: "100vh",
        }}>
        {selectedCom === "dashboard" && <Dashboard />}
        {selectedCom === "sale" && <Sale />}
        {selectedCom === "payment" && <Payment />}
        {selectedCom === "client" && <Client />}
        {selectedCom === "stock" && <Stock />}
        {selectedCom === "products" && <Product />}
        {selectedCom === "provider" && <Provider />}
        {selectedCom === "profile" && <CompanyProfile />}
        {selectedCom === "bank" && <BankComp />}
        {selectedCom === "country" && <CountryComp />}
        {selectedCom === "category" && <CategoryComp />}
        {selectedCom === "clientSheet" && <ClientSheet />}
        {selectedCom === "stockSheet" && <StockSheet />}
        {selectedCom === "transaction" && <TransactionComp />}
        {selectedCom === "profit" && <ProfitComp />}
      </Box>
    </Box>
  );
}

export default Dashboad;
