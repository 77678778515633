import {
  Box,
  Button,
  MenuItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Layout } from "../dashboard/dashboard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataTable from "./ProviderTable";
import CustomDialogue from "../reuseableComp/Modal";
import { Typo } from "../payment";
import { CustomField } from "../client";
import { PersonLogo } from "../../assets/Svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  getCountry,
  getProvider,
  saveProvider,
  updateProvider,
} from "../../apiservices";
import { toast } from "react-toastify";
import Search from "../searchBar";
const Btn = styled(Button)({
  minWidth: "126px",
  height: "36px",
  padding: "4px",
  borderRadius: "4px",
  textTransform: "none",
  background: "#FFFFFF",
  color: "#52b335",
  marginRight: 10,
  "&:hover": {
    background: "#F6F6F6",
  },
  "&:active": {
    background: "#FFFFFF",
  },
});
export const schema = yup
  .object({
    fName: yup.string().required(),
    cName: yup.string().required(),
    address: yup.string().required(),
    num: yup.string().required(),
  })
  .required();

const Provider = () => {
  const [state, setState] = useState({
    openModal: false,
    providersData: [],
    check: false,
    updatedData: [],
    searchData: [],
    cityArr: [],
    city: "",
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const handleModal = (data = "") => {
    setState((prev) => ({
      ...prev,
      openModal: !prev.openModal,
      check: false,
      updatedData: data,
    }));
    if (data) {
      setValue("fName", data.providersName);
      setValue("cName", data.companyName);
      setState((prev) => ({ ...prev, city: data.city }));

      setValue("address", data.address);
      setValue("num", data.mobileNo);
    }
  };
  const getProviders = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    let obj = {
      company_code: userData.company_code,
    };
    let res = await getProvider(obj);
    if (res) {
      setState((prev) => ({
        ...prev,
        providersData: res.items,
        searchData: res.items,
      }));
    }
  };
  const getCount = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    let arr = [];
    let obj = {
      company_code: userData.company_code,
    };
    let res = await getCountry(obj);
    if (res) {
      res.countries.map((item) => arr.push(item.city));
      setState((prev) => ({ ...prev, cityArr: arr }));
    }
  };
  useEffect(() => {
    getProviders();
    getCount();
  }, []);

  const onSubmit = async (data) => {
    const userData = JSON.parse(localStorage.getItem("user"));

    if (state.check) {
      let obj = {
        _id: state.updatedData._id,
        _rev: state.updatedData._rev,
        company_code: userData.company_code,
        providersName: data.fName,
        companyName: data.cName,
        city: state.city,
        mobileNo: data.num,
        address: data.address,
      };
      let res = await updateProvider(obj);
      if (res) {
        toast.success("Provider updated successfully");
        getProviders();
        handleModal();
      }
    } else {
      let obj = {
        company_code: userData.company_code,
        providersName: data.fName,
        companyName: data.cName,
        city: state.city,
        mobileNo: data.num,
        address: data.address,
      };
      let res = await saveProvider(obj);
      if (res) {
        toast.success("Provider saved successfully");
        getProviders();
        handleModal();
      }
    }
  };
  const handleCheck = () => {
    setState((prev) => ({ ...prev, check: !prev.check }));
  };
  const updateData = (data) => {
    setState((prev) => ({ ...prev, providersData: data }));
  };
  const handleChange = (e) => {
    let { name } = e.target;
    let { value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <Stack sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <>
        <Layout
          justifyContent="space-between"
          alignItems={"center"}
          direction={"row"}>
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "24px",
              fontWeight: 700,
            }}></Typography>
          <Typography sx={{ color: "#FFF", fontSize: "24px", fontWeight: 700 }}>
            Providers
          </Typography>
          <Btn onClick={handleModal}>
            <AddCircleIcon fontSize="small" sx={{ mx: 1 }} /> Add new provider
          </Btn>
        </Layout>
        <Stack sx={{ padding: "20px" }}>
          <Stack
            justifyContent={"start"}
            alignItems={"start"}
            sx={{
              width: "50%",
            }}>
            <Search
              data={state.searchData}
              updateData={(data) => updateData(data)}
              place1={" Search by Provider name"}
              place2={""}
            />
          </Stack>
          <DataTable
            editProvider={handleModal}
            data={state.providersData}
            handleCheck={handleCheck}
            getData={getProviders}
          />
        </Stack>
      </>
      <CustomDialogue
        open={state.openModal}
        handleClose={handleModal}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Box sx={{ color: "#52b335" }}>
              <PersonLogo />
            </Box>
            <Typo>Add new Provider</Typo>
          </Stack>
        }
        content={
          <Stack sx={{ padding: 1 }}>
            <Stack gap={2} sx={{ mb: 2 }}>
              <Stack>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  label="Provider Name"
                  {...register("fName")}
                  sx={{
                    m: 0,
                    background: "#F6F6F6",
                    "& label.Mui-focused": {
                      color: "#52b335",
                    },
                  }}
                />
                {errors.fName && (
                  <p style={{ color: "red" }}>{errors.fName?.message}</p>
                )}
              </Stack>
            </Stack>
            <Stack gap={2} sx={{ mb: 2 }}>
              <Stack>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  label="Company Name"
                  {...register("cName")}
                  sx={{
                    background: "#F6F6F6",
                    "& label.Mui-focused": {
                      color: "#52b335",
                    },
                  }}
                />
                {errors.cName && (
                  <p style={{ color: "red" }}>{errors.cName?.message}</p>
                )}
              </Stack>
              <Stack>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  label="Mobile Number"
                  {...register("num")}
                  sx={{
                    background: "#F6F6F6",
                    "& label.Mui-focused": {
                      color: "#52b335",
                    },
                  }}
                />
                {errors.num && (
                  <p style={{ color: "red" }}>{errors.num?.message}</p>
                )}
              </Stack>
            </Stack>
            <Stack gap={2} sx={{ mb: 3 }}>
              <Stack>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  label="City"
                  select
                  name="city"
                  value={state.city}
                  onChange={handleChange}
                  sx={{
                    background: "#F6F6F6",
                    "& label.Mui-focused": {
                      color: "#52b335",
                    },
                  }}>
                  {state.cityArr.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </CustomField>
              </Stack>
              <Stack>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  label="Address"
                  {...register("address")}
                  sx={{
                    background: "#F6F6F6",
                    "& label.Mui-focused": {
                      color: "#52b335",
                    },
                  }}
                />
                {errors.address && (
                  <p style={{ color: "red" }}>{errors.address?.message}</p>
                )}
              </Stack>
            </Stack>

            <Stack spacing={3} direction={"row"}>
              <Button
                onClick={handleModal}
                size="small"
                sx={{
                  background: "#FFF",
                  width: "200px",
                  textTransform: "none",

                  color: "#9F9E9E",
                  "&:hover": {
                    background: "#EFEFEF",
                  },
                }}>
                Cancel
              </Button>
              <Button
                onClick={handleSubmit(onSubmit)}
                size="small"
                sx={{
                  width: "200px",
                  textTransform: "none",
                  background: "#52b335",
                  color: "#FFF",
                  "&:hover": {
                    background: "#61D140",
                  },
                }}>
                {state.check ? "update provider" : " Save provider"}
              </Button>
            </Stack>
          </Stack>
        }
      />
    </Stack>
  );
};

export default Provider;
