import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, styled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteProvider } from "../../apiservices";
import { toast } from "react-toastify";
const Btn = styled(Button)({
  textTransform: "none",
  background: "#EEF4FF",
  color: "#52b335",
  "&:hover": {
    color: "#FFF",
    background: "#52b335",
  },
});

export default function DataTable({
  editProvider,
  data,
  handleCheck,
  getData,
}) {
  if (data) {
    data.forEach((element) => {
      element["id"] = element._id;
    });
  }
  const columns = [
    { field: "providersName", headerName: "Name" },
    { field: "companyName", headerName: "Company", width: 220 },
    { field: "mobileNo", headerName: "Mobile Number", width: 150 },
    {
      field: "address",
      headerName: "Address",
      width: 200,
    },
    {
      field: "city",
      headerName: "City",
      sortable: true,
      width: 150,
    },

    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Btn
            variant="contained"
            color="primary"
            size="small"
            onClick={() => edit(params.row)}>
            Edit
          </Btn>

          {/* You can add more buttons or customize based on your requirements */}
        </strong>
      ),
    },
    {
      field: "remove",
      headerName: "Remove",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <strong style={{ color: "red" }}>
          <DeleteIcon onClick={() => deleteRow(params.row)} />
          {/* You can add more buttons or customize based on your requirements */}
        </strong>
      ),
    },
  ];

  const rows = data;
  const edit = (params) => {
    editProvider(params);
    handleCheck();
  };
  const deleteRow = async (params) => {
    let obj = {
      _id: params._id,
    };
    let res = await deleteProvider(obj);
    if (res) {
      toast.error("provider deleted successfully");
      getData();
    }
  };
  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        pageSizeOptions={[20, 50]}
        checkboxSelection
      />
    </div>
  );
}
