import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#52b335",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function SimpleTable({ dataTable, removeItem }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: "400px" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell align="right">Action</StyledTableCell>
            <StyledTableCell align="right">Old Value</StyledTableCell>
            <StyledTableCell align="right">Change</StyledTableCell>
            <StyledTableCell align="right">New Value</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {dataTable.map((row, key) => ( */}
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              {"12-12-2023 02:41:33 pm"}
            </StyledTableCell>
            <StyledTableCell align="right">{"Sold"}</StyledTableCell>
            <StyledTableCell align="right">{"53"}</StyledTableCell>
            <StyledTableCell align="right">{"-4"}</StyledTableCell>
            <StyledTableCell align="right">{"59"}</StyledTableCell>
          </StyledTableRow>
          {/* ))} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
