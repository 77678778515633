import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, styled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { AppReg } from "../../assets/Svg";
import { toast } from "react-toastify";
import {
  deletePayments,
  deleteSpecificPaymentFromClient,
  getClientById,
} from "../../apiservices";
const Btn = styled(Button)({
  textTransform: "none",
  background: "#EEF4FF",
  color: "#52b335",
  "&:hover": {
    color: "#FFF",
    background: "#52b335",
  },
});

export default function DataTable({
  editPayment,
  view,
  viewLedger,
  data,
  getData,
  handleCheck,
  clientData,
}) {
  let user = localStorage.getItem("user");
  let parsed = JSON.parse(user);
  if (data) {
    data.forEach((element) => {
      element["id"] = element?.paymentId;
      let filtered = clientData.filter((item) => item._id == element?.payerId);
      element["clientName"] = filtered && filtered[0]?.clientName;
    });
  }
  const columns = [
    { field: "date", headerName: "Date" },
    { field: "paymentNumber", headerName: "Payment#" },
    { field: "received_by", headerName: "Received By", width: 150 },
    {
      field: "payer",
      headerName: "Payer",
      width: 250,
    },
    {
      field: "type",
      headerName: "Transaction Mode",
      sortable: true,
      width: 150,
    },
    { field: "amount", headerName: "Amount" },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Btn
            variant="contained"
            color="primary"
            size="small"
            onClick={() => view(params.row)}>
            view Payments
          </Btn>
          {/* You can add more buttons or customize based on your requirements */}
        </strong>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <>
          <Btn
            variant="contained"
            color="primary"
            size="small"
            onClick={() => edit(params.row)}>
            <AppReg />
          </Btn>
        </>
      ),
    },
    {
      field: "remove",
      headerName: "Remove",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <>
          <strong style={{ color: "red" }}>
            <DeleteIcon onClick={() => deleteRow(params.row)} />
            {/* You can add more buttons or customize based on your requirements */}
          </strong>
        </>
      ),
    },
  ];

  const rows = data ? data : [];
  const edit = (params) => {
    editPayment(params);
    handleCheck();
  };
  const deleteRow = async (params) => {
    let filtered = clientData.filter(
      (item) => item.clientName === params.payer
    );
    let clientObj = {
      _id: filtered[0]?._id,
      paymentId: params.paymentId,
    };
    let obj = {
      paymentId: params.paymentId,
      company_code: parsed.company_code,
    };
    let res = await deletePayments(obj);
    if (res) {
      let obj = {
        _id: filtered && filtered[0]?._id,
      };
      await deleteSpecificPaymentFromClient(clientObj);
      await getClientById(obj);
      toast.error("payment deleted successfully");
      getData();
    }
  };
  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        pageSizeOptions={[20, 50]}
        checkboxSelection
      />
    </div>
  );
}
