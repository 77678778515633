import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Layout } from "../dashboard/dashboard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataTable from "./TableProduct";
import CustomDialogue from "../reuseableComp/Modal";
import { Typo } from "../payment";
import { CustomField } from "../client";
import { CategoryLogo } from "../../assets/Svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Autocomplete from "@mui/material/Autocomplete";

import {
  getCategories,
  getProduct,
  saveProduct,
  updateProduct,
} from "../../apiservices";
import { toast } from "react-toastify";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SimpleTable from "./SimpleTable";
import Search from "../searchBar";
const Btn = styled(Button)({
  minWidth: "126px",
  height: "36px",
  padding: "4px",
  borderRadius: "4px",
  textTransform: "none",
  background: "#FFFFFF",
  marginRight: 10,
  color: "#52b335",
  "&:hover": {
    background: "#F6F6F6",
  },
  "&:active": {
    background: "#FFFFFF",
  },
});

export const schema = yup
  .object({
    modalName: yup.string().required(),
    price: yup.string().required(),
    salePrice: yup.string().required(),
    stock: yup.string().required(),
  })
  .required();

const Product = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [state, setState] = useState({
    openModal: false,
    brandName: "",
    arrayList: [],
    productData: [],
    check: false,
    updatedData: [],
    stockQuantity: 0,
    addStock: 0,
    stock: false,
    detailModal: false,
    productFieldData: [],
    brand: "",
    product: "",
    selectedProduct: "",
    categoriesArr: [],
    detailsData: {},
  });

  const handleModal = (data = "") => {
    setState((prev) => ({
      ...prev,
      openModal: !prev.openModal,
      check: false,
      updatedData: data,
      stockQuantity: data?.currentStock,
      brand: data?.brand,
      selectedProduct: data?.itemName,
    }));
    if (data) {
      setValue("brandName", data.brand);
      setValue("itemName", data.itemName);
      setValue("modalName", data.modelName);
      setValue("price", data.costPrice);
      setValue("salePrice", data.salePrice);
      setValue("stock", data.currentStock);
    }
  };
  const openDetail = (data = "") => {
    setState((prev) => ({
      ...prev,
      detailModal: !prev.detailModal,
      detailsData: data,
    }));
  };
  const handleChange = (e) => {
    let { value } = e.target;
    let { name } = e.target;
    let arr = [];
    setState((prev) => ({ ...prev, [name]: value }));
    if (name === "brand") {
      let filtered = state.categoriesArr.filter((item) => item.brand === value);
      filtered.map((item) => arr.push(item.category));
      setState((prev) => ({ ...prev, productFieldData: arr }));
    }
  };
  const getProducts = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    let obj = {
      company_code: userData.company_code,
    };
    let res = await getProduct(obj);
    if (res) {
      setState((prev) => ({
        ...prev,
        productData: res.items,
        searchData: res.items,
      }));
    }
  };
  useEffect(() => {
    getProducts();
    getCategory();
  }, []);
  const onSubmit = async (data) => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (state.check) {
      let obj = {
        _id: state.updatedData._id,
        _rev: state.updatedData._rev,
        company_code: userData.company_code,
        brand: state.brand,
        itemName: state.product,
        modelName: data.modalName,
        currentStock: data.stock,
        costPrice: data.price,
        salePrice: data.salePrice,
      };
      let res = await updateProduct(obj);
      if (res) {
        toast.success("product updated successfully");
        getProducts();
        handleModal();
      }
    } else {
      let obj = {
        company_code: userData.company_code,
        brand: state.brand,
        itemName: state.product,
        modelName: data.modalName,
        currentStock: data.stock,
        costPrice: data.price,
        salePrice: data.salePrice,
      };
      let res = await saveProduct(obj);
      if (res) {
        toast.success("product saved successfully");
        getProducts();
        handleModal();
      }
    }
  };
  const getCategory = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    let obj = {
      company_code: userData.company_code,
    };
    let res = await getCategories(obj);
    let arr = [];
    res.categories.map((item) => arr.push(item.brand));
    const arrayWithDuplicates = arr;
    const uniqueArray = [...new Set(arrayWithDuplicates)];
    if (res) {
      setState((prev) => ({
        ...prev,
        categoriesArr: res.categories,
        arrayList: uniqueArray,
      }));
    }
  };
  const handleCheck = () => {
    setState((prev) => ({ ...prev, check: !prev.check }));
  };
  const handleStock = () => {
    setState((prev) => ({
      ...prev,
      stock: !prev.stock,
    }));
    setValue("stock", Number(state.addStock) + Number(state.stockQuantity));
  };
  const handleOptionChange = (event, newOption) => {
    if (newOption) {
      setState((prev) => ({
        ...prev,
        selectedProduct: newOption,
        product: newOption,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        selectedProduct: "",
        product: "",
      }));
    }
  };
  const handleInputChange = (event, newInputValue) => {
    if (newInputValue != "undefined") {
      if (newInputValue) {
        setState((prev) => ({ ...prev, product: newInputValue }));
      }
    }
  };
  const updateData = (data) => {
    setState((prev) => ({ ...prev, productData: data }));
  };
  return (
    <Stack sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <>
        <Layout
          justifyContent="space-between"
          alignItems={"center"}
          direction={"row"}>
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "24px",
              fontWeight: 700,
            }}></Typography>
          <Typography sx={{ color: "#FFF", fontSize: "24px", fontWeight: 700 }}>
            Products
          </Typography>
          <Btn onClick={handleModal}>
            <AddCircleIcon fontSize="small" sx={{ mx: 1 }} /> Add new products
          </Btn>
        </Layout>
        <Stack sx={{ padding: "20px" }}>
          <Stack
            justifyContent={"start"}
            alignItems={"start"}
            sx={{
              width: "50%",
            }}>
            <Search
              data={state.searchData}
              updateData={(data) => updateData(data)}
              place1={" Search by Model no"}
              place2={" & Brand"}
            />
          </Stack>
          <DataTable
            editProduct={(data) => handleModal(data)}
            data={state.productData}
            handleCheck={handleCheck}
            getData={getProducts}
            openDetail={openDetail}
          />
        </Stack>
      </>
      <CustomDialogue
        open={state.openModal}
        handleClose={handleModal}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Box sx={{ color: "#52b335" }}>
              <CategoryLogo />
            </Box>
            <Typo>Add new Product</Typo>
          </Stack>
        }
        content={
          <Stack sx={{ padding: 1 }}>
            <Stack gap={2} sx={{ mb: 2 }}>
              <Stack>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  name="brand"
                  value={state.brand}
                  onChange={handleChange}
                  label="Select Brand">
                  {state.arrayList.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </CustomField>
              </Stack>
              <Stack>
                <Autocomplete
                  options={state.productFieldData}
                  fullWidth
                  value={state.selectedProduct}
                  inputValue={state.product}
                  onChange={handleOptionChange}
                  onInputChange={handleInputChange}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <CustomField
                      {...params}
                      label="Select or Type category name"
                      variant="outlined"
                      // InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Stack>
            </Stack>
            <Stack gap={2} sx={{ mb: 2 }}>
              <Stack>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  label="Model Name"
                  {...register("modalName")}
                  sx={{
                    background: "#F6F6F6",
                    "& label.Mui-focused": {
                      color: "#52b335",
                    },
                  }}
                />
                {errors.modalName && (
                  <p style={{ color: "red" }}>{errors.modalName?.message}</p>
                )}
              </Stack>
              <Stack>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  label="Purchase Price(Rs)"
                  {...register("price")}
                  sx={{
                    background: "#F6F6F6",
                    "& label.Mui-focused": {
                      color: "#52b335",
                    },
                  }}
                />
                {errors.price && (
                  <p style={{ color: "red" }}>{errors.price?.message}</p>
                )}
              </Stack>
            </Stack>
            <Stack gap={2} sx={{ mb: 3 }}>
              <Stack>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  label="Sale Price(Rs)"
                  {...register("salePrice")}
                  sx={{
                    background: "#F6F6F6",
                    "& label.Mui-focused": {
                      color: "#52b335",
                    },
                  }}
                />
                {errors.salePrice && (
                  <p style={{ color: "red" }}>{errors.salePrice?.message}</p>
                )}
              </Stack>
              {state.check ? (
                <Stack>
                  <OutlinedInput
                    fullWidth
                    id="outlined-adornment-password"
                    {...register("stock")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleStock}>
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    sx={{
                      background: "#F6F6F6",
                      "& label.Mui-focused": {
                        color: "#52b335",
                      },
                    }}
                    label="Current Stock"
                  />
                  {errors.stock && (
                    <p style={{ color: "red" }}>{errors.stock?.message}</p>
                  )}
                  {state.stock && (
                    <Stack
                      direction={"row"}
                      sx={{
                        mt: 1,
                      }}>
                      <CustomField
                        fullWidth
                        id="outlined-select-currency"
                        label="Add Stock"
                        size="small"
                        name="addStock"
                        value={state.addStock}
                        onChange={handleChange}
                        sx={{
                          background: "#F6F6F6",
                          "& label.Mui-focused": {
                            color: "#52b335",
                          },
                        }}
                      />
                      <Button
                        onClick={handleStock}
                        size="small"
                        sx={{
                          textTransform: "none",
                          background: "#52b335",
                          color: "#FFF",
                          "&:hover": {
                            background: "#61D140",
                          },
                        }}>
                        Add
                      </Button>
                    </Stack>
                  )}
                </Stack>
              ) : (
                <Stack>
                  <CustomField
                    fullWidth
                    id="outlined-select-currency"
                    {...register("stock")}
                    label="Current Stock"
                    sx={{
                      background: "#F6F6F6",
                      "& label.Mui-focused": {
                        color: "#52b335",
                      },
                    }}
                  />
                  {errors.stock && (
                    <p style={{ color: "red" }}>{errors.stock?.message}</p>
                  )}
                </Stack>
              )}
            </Stack>

            <Stack spacing={3} direction={"row"}>
              <Button
                onClick={handleModal}
                size="small"
                sx={{
                  background: "#FFF",
                  width: "200px",
                  textTransform: "none",

                  color: "#9F9E9E",
                  "&:hover": {
                    background: "#EFEFEF",
                  },
                }}>
                Cancel
              </Button>
              <Button
                onClick={handleSubmit(onSubmit)}
                size="small"
                sx={{
                  width: "200px",
                  textTransform: "none",
                  background: "#52b335",
                  color: "#FFF",
                  "&:hover": {
                    background: "#61D140",
                  },
                }}>
                {state.check ? "update product" : " Save product"}
              </Button>
            </Stack>
          </Stack>
        }
      />
      <CustomDialogue
        open={state.detailModal}
        handleClose={openDetail}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typo>Summary</Typo>
          </Stack>
        }
        content={
          <Stack sx={{ padding: 1 }}>
            <Stack sx={{ mt: 2 }}>
              <SimpleTable dataTable={state.detailsData} />
            </Stack>
          </Stack>
        }
      />
    </Stack>
  );
};

export default Product;
