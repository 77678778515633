import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, styled } from "@mui/material";
import { deleteProduct } from "../../apiservices";
import { toast } from "react-toastify";
const Btn = styled(Button)({
  textTransform: "none",
  background: "#EEF4FF",
  color: "#52b335",
  "&:hover": {
    color: "#FFF",
    background: "#52b335",
  },
});

export default function DataTable({
  editProduct,
  data,
  handleCheck,
  getData,
  openDetail,
}) {
  if (data) {
    data.forEach((element) => {
      element["id"] = element._id;
    });
  }
  const columns = [
    { field: "brand", headerName: "Brands" },
    { field: "itemName", headerName: "Products" },
    { field: "modelName", headerName: "Model", width: 150 },
    {
      field: "costPrice",
      headerName: "Purchase Price",
      width: 150,
    },
    {
      field: "salePrice",
      headerName: "Sale Price",
      sortable: true,
      width: 150,
    },
    {
      field: "currentStock",
      headerName: "Current Stock",
      sortable: true,
      width: 150,
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Btn
            variant="contained"
            color="primary"
            size="small"
            onClick={() => edit(params.row)}>
            Edit
          </Btn>
        </strong>
      ),
    },
    {
      field: "details",
      headerName: "Details",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Btn
            variant="contained"
            color="primary"
            size="small"
            onClick={() => openDetail(params.row)}>
            Details
          </Btn>

          {/* You can add more buttons or customize based on your requirements */}
        </strong>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Btn
            variant="contained"
            color="primary"
            size="small"
            onClick={() => deleteRow(params.row)}>
            <span style={{ color: "#EB5757" }}>Del</span>
          </Btn>

          {/* You can add more buttons or customize based on your requirements */}
        </strong>
      ),
    },
  ];

  const rows = data;
  const edit = (params) => {
    editProduct(params);
    handleCheck();
  };
  const deleteRow = async (params) => {
    let obj = {
      _id: params._id,
    };
    let res = await deleteProduct(obj);
    if (res) {
      toast.error("product deleted successfully");
      getData();
    }
  };
  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        pageSizeOptions={[20, 50]}
        checkboxSelection
      />
    </div>
  );
}
