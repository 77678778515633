import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, styled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteClient } from "../../apiservices";
import { toast } from "react-toastify";
const Btn = styled(Button)({
  textTransform: "none",
  background: "#EEF4FF",
  color: "#52b335",
  "&:hover": {
    color: "#FFF",
    background: "#52b335",
  },
});

export default function DataTable({
  editLedger,
  data,
  handleCheck,
  getData,
  viewLedger,
}) {
  if (data) {
    data.forEach((element) => {
      element["id"] = element._id;
    });
  }
  const columns = [
    { field: "clientName", headerName: "Client Name", width: 250 },
    { field: "mobileNumber", headerName: "Mobile No#", width: 150 },
    {
      field: "city",
      headerName: "City",
      width: 150,
    },
    {
      field: "address",
      headerName: "Address",
      sortable: true,
      width: 200,
    },
    {
      field: "currentBalance",
      headerName: "Current Balance",
      width: 100,
    },
    {
      field: "view",
      headerName: "View Ledger",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Btn
            variant="contained"
            color="primary"
            size="small"
            onClick={() => viewLedger(params.row)}>
            view Ledger
          </Btn>
          {/* You can add more buttons or customize based on your requirements */}
        </strong>
      ),
    },
    {
      field: "actions",
      headerName: "Edit",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <Btn
            variant="contained"
            color="primary"
            size="small"
            onClick={() => edit(params.row)}>
            Edit
          </Btn>
          {/* You can add more buttons or customize based on your requirements */}
        </strong>
      ),
    },

    {
      field: "remove",
      headerName: "Remove",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <strong style={{ color: "red" }}>
          <DeleteIcon onClick={() => deleteRow(params.row)} />
          {/* You can add more buttons or customize based on your requirements */}
        </strong>
      ),
    },
  ];

  const rows = data;
  const edit = (params) => {
    editLedger(params);
    handleCheck();
  };
  const deleteRow = async (params) => {
    let obj = {
      _id: params._id,
    };
    let res = await deleteClient(obj);
    if (res) {
      toast.error("Client deleted successfully");
      getData();
    }
  };
  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        pageSizeOptions={[20, 50]}
        checkboxSelection
      />
    </div>
  );
}
