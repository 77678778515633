import {
  Box,
  Button,
  MenuItem,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../dashboard/dashboard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataTable from "./ClientTable";
import CustomDialogue from "../reuseableComp/Modal";
import { Typo } from "../payment";
import { Back, ClientLogo } from "../../assets/Svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  getClient,
  getClientById,
  getCountry,
  saveClient,
  updateClient,
} from "../../apiservices";
import { toast } from "react-toastify";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SimpleTable from "./SimpleTable";
import ReactToPrint from "react-to-print";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Search from "../searchBar";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

const Btn = styled(Button)({
  // minWidth: "126px",
  height: "36px",
  padding: "4px",
  borderRadius: "4px",
  textTransform: "none",
  background: "#FFFFFF",
  color: "#52b335",
  marginRight: 10,
  "&:hover": {
    background: "#F6F6F6",
  },
  "&:active": {
    background: "#FFFFFF",
  },
});
export const BackBtn = styled(Button)({
  minWidth: "100px",
  height: "30px",
  padding: "4px",
  borderRadius: "4px",
  textTransform: "none",
  background: "#FFFFFF",
  color: "#52b335",
  marginRight: 10,
  "&:hover": {
    background: "#F6F6F6",
  },
  "&:active": {
    background: "#FFFFFF",
  },
});
export const Lay = styled(Stack)({
  // width: "700px",
  height: "40px",
  borderRadius: "8px",
  // border: " 0px 0px 1px 0px",
  background:
    "linear-gradient(0deg, #9F9E9E, #9F9E9E) linear-gradient(0deg, #FFFFFF, #FFFFFF)",
  borderBottom: "1px solid #9F9E9E",
  borderLeft: "1px solid #9F9E9E",
  borderRight: "1px solid #9F9E9E",
});
export const CustomField = styled(TextField)({
  mb: 2,
  background: "#F6F6F6",
  borderRadius: "10px",
  boxShadow: "rgb(77, 175, 81) 0px 3px 7px",
  "& label.Mui-focused": {
    color: "#52b335",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#52b335",
    },
    "&:hover fieldset": {
      borderColor: "#52b335",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#52b335",
    },
  },
});
export const schema = yup
  .object({
    fName: yup.string().required(),
    address: yup.string().required(),
    num: yup.string().required(),
  })
  .required();
const Client = () => {
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const componentRef = useRef();

  const [state, setState] = useState({
    openModal: false,
    clientData: [],
    check: false,
    updatedData: [],
    ledgerData: {},
    clientDataById: [],
    cityArr: [],
    city: "",
    currBalance: 0,
    searchData: [],
    clientName: "",
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const handleModal = (data = "") => {
    setState((prev) => ({
      ...prev,
      openModal: !prev.openModal,
      check: false,
      updatedData: data,
      currBalance: data?.openingBalance,
      clientName: data.clientName,
    }));
    if (data) {
      setState((prev) => ({ ...prev, city: data.city }));
      setValue("address", data.address);
      setValue("num", data.mobileNumber);
      setValue("name", data.clientName);
    }
  };
  const getClientsData = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    let obj = {
      company_code: userData.company_code,
    };
    let res = await getClient(obj);
    res.client.forEach((item, id) => {
      item["id"] = id + 1;
      item["name"] = item.clientName;
    });
    if (res) {
      setState((prev) => ({
        ...prev,
        clientData: res.client,
        searchData: res.client,
      }));
    }
  };
  useEffect(() => {
    getClientsData();
    getCount();
  }, []);
  const getClientData = async (data) => {
    let obj = {
      _id: data._id,
    };
    let res = await getClientById(obj);

    setState((prev) => ({
      ...prev,
      clientDataById: res.data,
    }));
  };
  const viewLedger = (data = "") => {
    if (data) {
      getClientData(data);
    }
    setState((prev) => ({
      ...prev,
      viewLedger: !prev.viewLedger,
      ledgerData: data,
    }));
  };
  const onSubmit = async (data) => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (state.check) {
      let obj = {
        _id: state.updatedData._id,
        _rev: state.updatedData._rev,
        company_code: userData.company_code,
        clientName: data.fName,
        openingBalance: state.currBalance,
        city: state.city,
        mobileNumber: data.num,
        address: data.address,
      };
      let res = await updateClient(obj);
      if (res) {
        toast.success("client updated successfully");
        getClientsData();
        handleModal();
      }
    } else {
      let obj = {
        company_code: userData.company_code,
        clientName: data.fName,
        city: state.city,
        mobileNumber: data.num,
        address: data.address,
        openingBalance: state.currBalance,
      };
      let res = await saveClient(obj);
      if (res) {
        toast.success("client saved successfully");
        getClientsData();
        handleModal();
      }
    }
  };
  const handleCheck = () => {
    setState((prev) => ({ ...prev, check: !prev.check }));
  };
  const getCount = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    let arr = [];
    let obj = {
      company_code: userData.company_code,
    };
    let res = await getCountry(obj);
    if (res) {
      res.countries.map((item) => arr.push(item.city));
      setState((prev) => ({ ...prev, cityArr: arr }));
    }
  };
  const handleChange = (e) => {
    let { name } = e.target;
    let { value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const ComponentToPrint = React.forwardRef((props, ref) => (
    <Stack id="content-to-print" ref={ref} sx={{ padding: 3 }}>
      <Stack sx={{ mb: 3 }}>
        <Lay
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            paddingLeft: 2,
            paddingRight: 2,
            mt: 5,
            borderTop: "1px solid #9F9E9E",
          }}>
          <Typography>Client Name:</Typography>
          <Typography>{state.ledgerData.clientName}</Typography>
        </Lay>
        <Lay
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ paddingLeft: 2, paddingRight: 2 }}>
          <Typography>Contact Number:</Typography>
          <Typography>{state.ledgerData.mobileNumber}</Typography>
        </Lay>

        <Lay
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ paddingLeft: 2, paddingRight: 2 }}>
          <Typography>City:</Typography>
          <Typography>{state.ledgerData.city}</Typography>
        </Lay>
        <Lay
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ paddingLeft: 2, paddingRight: 2 }}>
          <Typography>Address:</Typography>
          <Typography>{state.ledgerData.address}</Typography>
        </Lay>

        <Lay
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ paddingLeft: 2, paddingRight: 2 }}>
          <Typography>Current Balance:</Typography>
          <Typography>{state.clientDataById?.currentBalance}</Typography>
        </Lay>
      </Stack>
      <SimpleTable
        data={state.clientDataById?.clientArray}
        clientData={state.ledgerData}
      />
      <Lay
        direction={fullScreen ? "column" : "row"}
        alignItems={"center"}
        justifyContent={"space-around"}
        sx={{ paddingRight: 2, mt: fullScreen ? 4 : 1 }}>
        <Stack direction={"row"} sx={{ mx: 2 }}>
          <Typography>Total Sales:</Typography>
          <Typography>{`Rs  ${state.clientDataById?.totalSales}`}</Typography>
        </Stack>
        <Stack direction={"row"} sx={{ mx: 2 }}>
          <Typography>Total Paid:</Typography>
          <Typography>{`Rs  ${state.clientDataById?.totalPaid}`}</Typography>
        </Stack>
        <Stack direction={"row"} sx={{ mx: 2 }}>
          <Typography>Total Payments:</Typography>
          <Typography>{`Rs ${state.clientDataById?.totalPayments}`}</Typography>
        </Stack>
        <Stack direction={"row"}>
          <Typography>Total Balance:</Typography>
          <Typography>{`Rs ${state.clientDataById?.totalBalance}`}</Typography>
        </Stack>
      </Lay>
    </Stack>
  ));
  const updateData = (data) => {
    setState((prev) => ({ ...prev, clientData: data }));
  };
  const handleOnSearch = (string) => {
    setState((prev) => ({ ...prev, clientName: string }));
  };
  const handleOnSelect = (item) => {
    setState((prev) => ({ ...prev, clientName: item.brand }));
  };
  return (
    <Stack sx={{ paddingLeft: 2, paddingRight: 2 }}>
      {state.viewLedger ? (
        <Stack>
          <Layout justifyContent="end" alignItems={"center"} direction={"row"}>
            <Btn onClick={viewLedger}>
              <Back sx={{ mx: 1 }} />
              {!fullScreen && "Back"}
            </Btn>
            <ReactToPrint
              trigger={() => (
                <Btn>
                  <PictureAsPdfIcon fontSize="small" sx={{ mx: 1 }} />
                  {!fullScreen && "PDF"}
                </Btn>
              )}
              content={() => componentRef.current}
            />

            <ReactToPrint
              trigger={() => (
                <Btn>
                  <PrintIcon fontSize="small" sx={{ mx: 1 }} />
                  {!fullScreen && "Print"}
                </Btn>
              )}
              content={() => componentRef.current}
            />
          </Layout>
          <ComponentToPrint ref={componentRef} />
        </Stack>
      ) : (
        <>
          <Layout
            justifyContent="space-between"
            alignItems={"center"}
            direction={"row"}>
            <Typography
              sx={{
                color: "#FFF",
                fontSize: "24px",
                fontWeight: 700,
              }}></Typography>
            <Typography
              sx={{ color: "#FFF", fontSize: "24px", fontWeight: 700 }}>
              Clients
            </Typography>
            <Btn onClick={handleModal}>
              <AddCircleIcon fontSize="small" sx={{ mx: 1 }} /> Add new client
            </Btn>
          </Layout>
          <Stack sx={{ padding: "20px" }}>
            <Stack
              justifyContent={"start"}
              alignItems={"start"}
              sx={{
                width: "50%",
              }}>
              <Search
                data={state.searchData}
                updateData={(data) => updateData(data)}
                place1={" Search by Client Name"}
                place2={""}
              />
            </Stack>
            <DataTable
              editLedger={(data) => handleModal(data)}
              data={state.clientData}
              handleCheck={handleCheck}
              getData={getClientsData}
              viewLedger={(data) => viewLedger(data)}
            />
          </Stack>
        </>
      )}
      <CustomDialogue
        open={state.openModal}
        handleClose={handleModal}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Box sx={{ color: "#52b335" }}>
              <ClientLogo />
            </Box>
            <Typo>Add new Client</Typo>
          </Stack>
        }
        content={
          <Stack sx={{ padding: 1 }}>
            <Stack sx={{ mb: 2 }}>
              {/* <ReactSearchAutocomplete
                styling={{
                  mb: 2,
                  background: "#F6F6F6",
                  borderRadius: "10px",
                  boxShadow: "rgb(77, 175, 81) 0px 3px 7px",
                  zIndex: 9999,
                }}
                items={state.clientData}
                onSearch={handleOnSearch}
                onSelect={handleOnSelect}
                autoFocus
                inputSearchString={state.clientName}
                showItemsOnFocus={true}
              /> */}
              <CustomField
                id="outlined-select-currency"
                label="Client Name"
                {...register("fName")}
                sx={{
                  background: "#F6F6F6",
                  "& label.Mui-focused": {
                    color: "#52b335",
                  },
                }}
              />
              {errors.fName && (
                <p style={{ color: "red" }}>{errors.fName?.message}</p>
              )}
            </Stack>

            <Stack sx={{ mb: 2 }}>
              <CustomField
                fullWidth
                id="outlined-select-currency"
                label="Mobile Number"
                {...register("num")}
                sx={{
                  background: "#F6F6F6",
                  "& label.Mui-focused": {
                    color: "#52b335",
                  },
                }}
              />
              {errors.num && (
                <p style={{ color: "red" }}>{errors.num?.message}</p>
              )}
            </Stack>
            <Stack sx={{ mb: 2 }}>
              <CustomField
                fullWidth
                id="outlined-select-currency"
                label="City"
                select
                name="city"
                value={state.city}
                onChange={handleChange}
                sx={{
                  background: "#F6F6F6",
                  "& label.Mui-focused": {
                    color: "#52b335",
                  },
                }}>
                {state.cityArr.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomField>
            </Stack>
            <Stack sx={{ mb: 2 }}>
              <CustomField
                fullWidth
                id="outlined-select-currency"
                label="opening Balance"
                name="currBalance"
                value={state.currBalance}
                onChange={handleChange}
                sx={{
                  background: "#F6F6F6",
                  "& label.Mui-focused": {
                    color: "#52b335",
                  },
                }}></CustomField>
            </Stack>
            <Stack sx={{ mb: 2 }}>
              <CustomField
                fullWidth
                id="outlined-select-currency"
                label="Address"
                {...register("address")}
                sx={{
                  background: "#F6F6F6",
                  "& label.Mui-focused": {
                    color: "#52b335",
                  },
                }}
              />
              {errors.address && (
                <p style={{ color: "red" }}>{errors.address?.message}</p>
              )}
            </Stack>
            <Stack spacing={3} direction={"row"}>
              <Button
                onClick={handleModal}
                size="small"
                sx={{
                  background: "#FFF",
                  width: "200px",
                  textTransform: "none",

                  color: "#9F9E9E",
                  "&:hover": {
                    background: "#EFEFEF",
                  },
                }}>
                Cancel
              </Button>
              <Button
                size="small"
                onClick={handleSubmit(onSubmit)}
                sx={{
                  width: "200px",
                  textTransform: "none",
                  background: "#52b335",
                  color: "#FFF",
                  "&:hover": {
                    background: "#61D140",
                  },
                }}>
                {state.check ? "Update Client" : " Save Client"}
              </Button>
            </Stack>
          </Stack>
        }
      />
    </Stack>
  );
};

export default Client;
